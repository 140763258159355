@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;700&display=swap");

.main-image {
  position: relative;
  height: 400px;
  background: url("../images/loginBg2.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  .main-heading {
    // position: absolute;
    // bottom: 50%;
    // left: 150px;
  }
}

.drop-down {
  // font-family: "Comic Neue", cursive;
  top: 656px;
  left: 556px;
  width: 220px;
  padding: 10px;
  height: 70px;
  background: #f1f9fc 0% 0%;
  border: none;
  box-shadow: 7px 7px 5px #d9ddddbf;
  border: 2px solid white;
  span {
    // font-weight: 900;
    font-weight: 600;
    margin-bottom: 0;
    color: $primaryColor;
    transition: 0.2s;
  }
  &:hover {
    background-color: lightyellow;
    border: 4px solid yellow;
    span {
      color: blue;
      // font-weight: bolder;
      // font-size: 1.2rem;
    }
  }
}
.drop-down-item {
  background: #eaeaea 0% 0%;
  li {
    top: 700px;
    left: 755px;
    width: 160px;
    height: 50px;
    background: #eaeaea 0% 0%;
    border-bottom: 1px solid white;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    button {
      color: $purple;
      border: none;
    }
  }
}

.grade-shadow {
  // font-family: "Comic Neue", cursive;
  // background-color: #f1f9fc;
  background-color: #cdeff9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height: 120px;
  // width: 120px;
  height: 150px;
  width: 150px;
  min-width: 150px;
  // margin: 0 50px;
  margin: 0 20px;
  padding: 10px;
  margin-top: 50px;
  border-radius: 5px;
  transition: 0.2s;
  border: 2px solid white;
  color: $primaryColor;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
    0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  &:hover {
    background-color: lightyellow;
    border: 4px solid yellow;
    span {
      color: blue;
      font-weight: bold;
      font-size: 0.9rem;
      transition: 0.2s;
    }
  }
}

.grade-icon-image {
  height: 80px;
  img {
    height: 100%;
    width: 100;
    object-fit: cover;
  }
}

.dropdown-menu.show {
  padding: 0;
}

.st-line {
  height: 450px;
  border-right: 2px solid $purple;
}

.tab-list {
  // background-color: #f1f9fc;
  background-color: #cdeff9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 120px;
  margin: 0 50px;
  padding: 10px;
  margin-top: 50px;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
    0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
}

.description-shadow {
  // background-color: #f1f9fc;
  background-color: #cdeff9;
  display: flex;
  flex-direction: column;
  align-items: initial;
  justify-content: left;
  height: auto;
  width: auto;
  margin: 0 50px;
  padding: 10px;
  margin-top: 50px;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
    0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
}

.coursePlanCss {
  flex-direction: column !important;
  li {
    list-style: none;
    color: white;
    font-size: 13px;
  }
}

.coursePlanContent {
  background: white;
  padding: 15px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  h4 {
    font-size: 1.2rem;
  }
  li {
    list-style: none;
    font-size: 13px;
  }
}

.coursePlanSubtopic {
  background: white;
  padding: 15px;
  margin-left: 50px;
  margin-right: 20px;
  margin-bottom: 10px;
  h5 {
    font-size: 1.1rem;
  }
  li {
    list-style: none;
    font-size: 13px;
    margin-left: 10px;
  }
}

.gradePageListBtn {
  cursor: pointer;
  color: $primaryColor;
}

.newBg {
  background: url("../images/childBg6.png");
  background-color: white;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: auto;
}
