@import "./variables";

.tt-post-template {
  //   border: 1px solid $primaryColor;
  // border-radius: 3px;
  padding: 1px;

  .text {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    cursor: default;
    width: 96%;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    height: auto;
    // max-height: 110px;
    padding-right: 5px;
  }
}

.tt-post-section {
  .rwt__tabs {
    .tt-post-template {
      &:nth-child(odd) {
        background-color: $tableAlternateBgColor !important;
      }
    }
  }
}

.postBgColor {
  background-color: $tableAlternateBgColor !important;
}

.autoHeight {
  height: auto !important;
}

.text {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  cursor: default;
  width: 96%;
  word-break: break-word;
}

.date-displayPosts {
  font-size: 12px;
}

.tt-postTargetList {
  display: block;
  text-align: left;
  line-height: 1;
  font-size: 12px;

  div {
    display: inline-block;
    background-color: $primaryBtnColor;
    padding: 5px 15px;
    color: $whiteColor;
    margin-right: 5px;
    border-radius: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
    cursor: default;
    white-space: nowrap;
    &:nth-child(even) {
      background-color: $dangerBtnColor;
    }
  }
}

.tt-postArticleTitle {
  margin-bottom: 0px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  margin-right: 10px;
}

.mediaImg {
  width: 205px;
  height: 154px;
  object-fit: cover;
}

.mediaImgBig {
  width: 405px;
  height: 290px;
  object-fit: cover;
  margin: 10px;
}
.mediaImgBigHistory {
  width: 405px !important;
  height: 290px !important;
  object-fit: cover !important;
  margin: 10px;
}

.tt-moreImageHolder {
  border: 1px solid $cancelBtnColor;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 154px;
  color: $cancelBtnColor;
  margin-left: 5px;
  font-size: 20px;
  line-height: 0;
  width: 205px;
}

.mediaIframe {
  width: 500px;
  height: 290px;
}

.mediaIframe-media {
  width: 53vw;
  height: 65vh;
}

.tt-theme-header {
  background-color: $calendarBorderColor;
  padding: 5px;
  // color: white;
  font-weight: bold;
  // text-shadow: 1px 1px 1px $borderColor;
  p {
    margin-bottom: 0;
  }
}

.tt-question-header {
  background-color: $attachFileBoxColor;
  padding-left: 5px;
  color: black;
  font-weight: bold;
  p {
    margin-bottom: 0;
    font-weight: 500;
    display: inline-block;
  }
  span:last-child {
    display: none;
  }
}

.tt-videoLNK {
  padding-left: 15px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 10px;
  .tt-videoLNKImage {
    border-right: 1px solid $primaryColor;
    text-align: center;
    img {
      max-height: 120px;
      max-width: 100%;
      object-fit: cover;
    }
  }
  p {
    font-size: 16px;
    font-weight: bold;
    // line-height: 5.5;
    margin-bottom: 0;
  }
}

.tt-linkReference {
  a {
    color: $primaryHoverColor;
    &:hover {
      text-decoration: none;
    }
  }
  &:nth-child(even) {
    background-color: $tableAlternateBgColor;
  }
}

.tt-themeQuestionHolder {
  border: 1px dashed $cancelBtnColor;
  padding: 3px 18px 3px 18px;
}

.tt-linkHolder {
  border: 1px dashed $cancelBtnColor;
  padding: 5px;
  margin-bottom: 10px;
}

.img-postDisplay {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.img-commentDisplay {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.academiRepoMarker {
  font-style: italic;
  font-size: 12px;
}

//for Subject selection in academic Repo
.tt-selectedSubjects {
  border: 1px solid $primaryColor;
  height: 120px;
  padding: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  p:first-child {
    margin-bottom: 0;
    background-color: $calendarBorderColor;
    color: black;
    font-weight: 500;
    padding-left: 5px;
  }
  ol {
    padding-left: 18px;
  }
}

.wordBreakHyphen {
  word-break: break-word;
}

//Responsive

@media screen and (max-width: 1024px) {
  .tt-postTargetList {
    div {
      max-width: 139px;
    }
  }
  .mobileMedia {
    width: 98%;
    iframe {
      width: inherit;
    }
  }
  .tt-moreImageHolder {
    height: 90px;
    font-size: 17px;
    width: 90px;
  }
  .mediaImg {
    width: 90px;
    height: 90px;
  }
  .text,
  .wordBreakHyphen {
    word-break: unset !important;
    hyphens: auto !important;
  }

  .editorPostContent {
    img {
      max-width: 300px;
    }
  }
}

@media screen and (max-width: 600px) {
  .tt-postTargetList {
    div {
      max-width: 139px;
    }
  }
  .mediaIframe-media {
    width: 100%;
  }
  .mediaImg {
    width: 120px;
    height: 120px;
  }
  .tt-moreImageHolder {
    height: 120px;
    font-size: 19px;
    width: 120px;
  }
}

@media screen and (max-width: 480px) {
  .mediaIframe-media {
    height: 40vh;
  }
}

@media screen and (max-width: 414px) {
  .img-commentDisplay {
    width: 60px;
    height: 60px;
  }
  .mobileCommentimage {
    width: 55px !important;
  }
}
@media screen and (max-width: 400px) {
  .mediaImg {
    width: 100px;
    height: 100px;
  }
  .tt-moreImageHolder {
    height: 100px;
    font-size: 17px;
    width: 100px;
  }
  .mediaImgBig {
    width: 100%;
    height: auto;
  }
  .editorPostContent {
    img {
      max-width: 277px;
    }
  }
}

@media screen and (max-width: 393px) {
}
@media screen and (max-width: 375px) {
  .editorPostContent {
    img {
      max-width: 252px;
    }
  }
}

@media screen and (max-width: 360px) {
  .img-commentDisplay {
    width: 45px;
    height: 45px;
  }
  .mobileCommentimage {
    width: 35px !important;
  }
  .editorPostContent {
    img {
      max-width: 237px;
    }
  }
}

@media screen and (max-width: 320px) {
  .mediaImg {
    width: 85px;
    height: 85px;
  }
  .tt-moreImageHolder {
    height: 85px;
    font-size: 17px;
    width: 85px;
  }
  .editorPostContent {
    img {
      max-width: 197px;
    }
  }
}

.newText {
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* border-radius: 3px; */
  /* cursor: default; */
  width: 96%;
  /* word-break: break-word; */
  padding: 3px;
}
