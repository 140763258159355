@import "./variables";
body {
  background-color: $permBgColor;
}

.activity-checkbox {
  margin-top: 8px !important;
}
.permission-activity-label {
  height: 30px;
  background-color: $permissionLabel;
  font-size: 1.1rem;
  color: $whiteColor;
  padding: 3px 0 5px 0;
  text-align: center;
  border-radius: 5px;
}

.permission-role-label {
  //   height: 40px;
  background-color: $permissionLabel;
  //   font-size: 1.1rem;
  color: $whiteColor;
  //   padding: 5px 2px 5px 3px;
  text-align: center;
  border-radius: 5px;
  outline: none !important;
}

.div-role {
  //   margin-top: 5px;
  //border: 1px solid $permissionLabel;
  border-radius: 10px;
}

.submit-button {
  height: 38px;
  width: 132px;
  padding: 3px 0 5px 0;
  border-radius: 10px !important;
  color: $whiteColor;
  background-color: $permissionLabel;
  outline: none !important;
}
.submit-button-list {
  height: 25px;
  width: 40px;
  //padding: 3px 0 5px 0;
  border-radius: 10px !important;
  color: $whiteColor;
  background-color: $permissionLabel;
  outline: none !important;
}

.submit-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"] {
  height: 18px !important;
  width: 18px !important;
}
.permission-table {
  font-size: 1.1rem !important;
}
select {
  //background-color: #bfd1ff !important;
  //background-color: $permissionLabel;
  color:$borderColor;
  border-radius: 5px;
  padding: 2px;
  width: 175px;
}

.action-list {
  display: inline-flex;
}
.action-list-actions {
  background-color: $permissionLabel;
  border-radius: 5px;
  color: $whiteColor;
  width: 30px;
  padding: 2px;
  outline: none !important;
}

.action-save-all {
  width: 80px !important;
}

#select-module {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.activity-row {
  display: contents;
}

.permissionTable {
  tr {
    th,
    td {
      padding: 5px 10px 5px 10px;
      vertical-align: middle;
    }
  }
}

.tt-permissionColumn {
  border: 1px solid $displayBackgroundColor;
  margin-top: 10px;
  height: 70vh;
  margin-bottom: 10px;
  overflow: auto;
  box-shadow: 2px 3px 3px $shadowColor;
}

.horiPermission {
  margin-top: 7px;
  margin-bottom: 7px;
}
