@import "./variables";

.tt-facilities-list {
  //   height: 47.2vh;
  height: 30.8vh;
  overflow: hidden;
  margin-bottom: 5px;
  overflow-y: scroll;
}

.tt-building-insert-form {
  padding: 2px;
}

.tt-add-new-building-form-header {
  height: 6vh;
  background-color:$borderColor;
  color: $whiteColor;
  display: flex;
  justify-content: center;
  padding: 7px;
  margin: 15px;
}

.tt-add-new-building-form {
  padding: 3px;
}

.tt-add-new-header-title {
  font-weight: bold;
}

.tt-facilities-add-mandatory {
  color: $dangerBtnColor;
  font-weight: bold;
  top: -4px;
  left: -26px;
  position: relative;
}

.tt-assessment-module-mandatory {
  color: $dangerBtnColor;
  font-weight: bold;
  top: -4px;
  position: relative;
}

.tt-facilities-add-room-mandatory {
  color: $dangerBtnColor;
  font-weight: bold;
  top: -4px;
  left: 2px;
  position: relative;
}

.tt-facilities-border {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
