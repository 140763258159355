@import "./variables";

.tt-inventoryItemListHolder {
  border: 1px solid #ccc;
  height: 55vh;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  overflow-x: hidden;
  overflow-y: auto;
}

.tt-inventoryCategoryListHolder {
  background-color: $primaryColor;
  padding: 8px;
  color: $whiteColor;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-bottom: 5px;
}

.assignInventoryHolder {
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 25px;
}
.accountConfigHolder {
  height: 48.7vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.accountStudentContainer {
  padding-top: 10px;
  .accountStudentRow {
    padding: 10px 0 10px 10px;
    border-bottom: 1px solid #ccc;
  }
  .activeAccountStudentRow {
    background-color: $selectedPrimaryColor;
  }
  .accountStudentHolder {
    height: 45.1vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .footerAccount {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $primaryColor;
    font-weight: bold;
    letter-spacing: 0.5px;
    cursor: default;
    color: white;
    text-shadow: 1px 1px 2px $borderColor;
    -webkit-user-select: none;
    user-select: none;
  }
}

.inventoryListHolder {
  max-height: 82vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.tt-paymentDiv {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 10px;
}

.tt-totalAdvanceDiv {
  margin-bottom: 10px;
  margin-bottom: 10px;
  background: $primaryColor;
  padding: 6px 0;
  color: $whiteColor;
  text-shadow: 1px 1px 2px $borderColor;
}
