@import "./variables";

.tt-flashCardOuterBox {
  // border: 1px solid $calendarBorderColor;
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  // overflow-x: auto;
  // overflow-y: hidden !important;
}

.tt-flashCardOuterBoxNew {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.tt-flashCardBox {
  height: 380px;
  // width: 100%;
  width: 240px;
  margin-top: 10px;
  box-shadow: 2px 3px 3px $shadowColor;
  padding: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: $primaryColor;
  color: $whiteColor;
  text-shadow: 1px 1px 1px $shadowColor;
  letter-spacing: 1px;
  .tt-input {
    width: 100%;
  }
  .tt-image {
    width: 100%;
    max-height: 180px;
  }
  .tt-flashCard-img-box {
    border: 1px solid #ccc;
    width: 100%;
    height: 120px;
    padding: 1px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .tt-imgDeleteFlashCard {
    position: absolute;
    right: 30px;
    top: 63px;
  }
}

//Flash Card Template css
.tt-cardDeckHolder {
  border-radius: 5px;
  margin-top: 5px;
  width: 100px;
  border: 1px solid #ccc;
  padding: 3px;
  height: 140px;
  cursor: pointer;
  .tt-cardDeck {
    background-color: $primaryColor;
    height: 100%;
    border-radius: 5px;
    width: 100%;
    color: $whiteColor;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 1px 1px 1px $editButtonColor;
    font-size: 21px;
  }

  &:hover {
    box-shadow: 2px 3px 3px $shadowColor;
  }
  &:hover > .tt-cardDeck {
    background-color: $primaryHoverColor;
  }
}
.tt-cardDeck-empty {
  border-radius: 5px;
  margin-top: 5px;
  width: 100px;
  border: 1px solid $calendarHolidayColor;
  padding: 3px;
  height: 140px;
  background-color: $whiteColor;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: $calendarHolidayColor;
}

.tt-listCards {
  border: 1px solid $calendarBorderColor;
  margin-top: 5px;
  border-radius: 5px;
  height: 83vh;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow-y: auto;
  overflow-x: hidden;
  .tt-flashCardThumb {
    // width: 100px;
    // height: 140px;
    flex: 0 0 50%;
    padding: 15px;
    margin-top: 13px;

    div {
      border: 1px solid #ccc;
      border-radius: 8px;
      cursor: pointer;
      height: 20vh;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
      &:hover {
        box-shadow: 2px 3px 3px $shadowColor;
      }
    }
  }
}

//To flip the div

.flip-card {
  text-shadow: 1px 1px 1px $shadowColor;
  letter-spacing: 1px;
  background-color: transparent;
  // height: 520px;
  height: 83vh;
  // width: 350px;
  // width: 420px;
  width: 100%;
  overflow: hidden;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  // border-radius: 15px;
  border-radius: 10px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-card-blank {
  text-align: center;
  border: 1px solid #ccc;
  justify-content: center;
  font-size: 22px;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.9s;
  transform-style: preserve-3d; //Removing this wont show the back div, instead shows backside of front div
}

.flip {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side*/
.flip-card-front {
  // background-color: $primaryColor;
  color: $whiteColor;
  box-shadow: 2px 3px 3px $shadowColor;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
}

.flip-card-front-content-holder {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: $primaryColor;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flash-hoverDiv {
  background-color: $flashHoverColor;
  z-index: 999999;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-shadow: 1px 1px 1px $borderColor;
}

/* Style the back side */
.flip-card-back-content-holder {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgb(250, 250, 250);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  color: $borderColor;
  font-weight: bold;
  text-shadow: none;
  font-size: 35px;
}
.flip-card-back {
  background-color: $primaryColor;
  color: $whiteColor;
  transform: rotateY(180deg);
  box-shadow: 2px 3px 3px $shadowColor;
  border: 15px solid $primaryColor;
  border: 1px solid #ccc;
  /* border: 15px solid #7798ed; */
  border-radius: 10px;
  padding: 15px;
}

.flip-image-holder {
  width: 95%;
  // height: 385px;
  height: 61vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 2px;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
}

.flashCard-question {
  font-size: 22px;
  margin-top: 8px;
}

.flash-hint {
  // position: absolute;
  // bottom: 15px;
  font-style: italic;
  color: grey;
  font-size: 12px;
}

.flash-hr {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 8px;
  border-top: 1px solid $whiteColor;
}

.flash-selection-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  text-shadow: 1px 1px 1px $shadowColor;
  letter-spacing: 1px;
}

.disableFlip {
  color: $cancelBtnColor;
  cursor: default !important;
}

.tt-flashCardImageError {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;
}

//Update Flash Cards Css

.tt-flashCardList {
  display: inline-block;
  /* float: left; */
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  .tt-flashCardListHolder {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 125px;
    height: 115px;
    margin-right: 15px;
    padding: 5px;
    display: inline-block;
    margin-bottom: 3px;
    position: relative;
    .tt-flashImgHolder {
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 85px;
      margin-right: 0;
      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
      }
    }
    .tt-flashUpdateQuestion {
      font-size: 12px;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.tt-flashDelete {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: 1px 1px 3px $borderColor;
  color: $primaryHoverColor;
}
