@import "./variables";

@mixin style($bdr, $bgColor, $height) {
  border: $bdr;
  background-color: $bgColor;
  height: $height;
}

.tt-familyPage-memberlist {
  height: 182px !important;
}

.tt-groupMember-container {
  @include style(7px solid $whiteColor, $whiteColor, 443px);
  box-shadow: 2px 3px 3px $shadowColor;
  margin-top: 20px;
  border-radius: 10px;
  cursor: default;
  overflow: hidden;
  transition: height 0.8s;
  .tt-familyMember-box {
    height: 120px !important;
    overflow-x: auto;
    overflow-y: hidden !important;
    align-items: center;
    flex-direction: column;
  }
  .tt-groupMember-box {
    @include style(1px solid $primaryColor, $whiteColor, 325px);
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: center;
    border-radius: 5px;
    box-shadow: inset 7px 7px 10px -5px $shadowColor;
    .tt-group-holder {
      @include style(1px solid $primaryColor, $whiteColor, 100px);
      margin: 8px;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      width: 100px;

      &:hover {
        box-shadow: 2px 3px 3px $shadowColor;
      }
      .tt-memeber-img {
        color: $primaryHoverColor;
        font-weight: bold !important;
        text-align: center;
        height: 85%;
        padding: 0 6px;
        img {
          height: 65px;
          width: 100%;
          object-fit: cover;
          border: 1px solid #ccc;
        }
      }
      .tt-member-name {
        color: $primaryColor;
        font-weight: bold !important;
        text-align: center;
        padding: 0 6px;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.minimize-groupMemberContainer {
  height: 55px;
}

.removeFooter {
  height: 387px;
}

//Responsive

@media screen and (max-width: 1024px) {
  .listHeading,
  .listTotal {
    font-size: 11px !important;
  }
  .tt-mobileGroupMember {
    display: none !important;
  }
  .tt-groupMember-container {
    height: 383px;
  }
}

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 320px) {
  .listHeading,
  .listTotal {
    font-size: 9px !important;
  }
  .tt-groupMember-container {
    height: 380px;
  }
}
