@import "./variables";

.tt-list_students {
  background-color: $primaryColor;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
  color: $whiteColor;
  text-shadow: 1px 1px 2px $borderColor;
  text-align: center;
  padding: 7px;
}

.tt-selectNotify {
  color: $calendarHolidayColor;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: -15px;
  position: relative;
  left: 25%;
}
.tt-notify {
  color: $calendarHolidayColor;
  display: inline;
  font-weight: 500;
  margin-left: 17%;
}
