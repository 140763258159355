@import "../variables";

.tt-navbar-heading-responsive {
  text-align: center;
  color: $whiteColor;
  font-weight: bold;
  cursor: default;
  font-size: 26px;
  position: relative;
  top: 12px;
  text-shadow: 1px 1px 1px $borderColor;
  display: none;
}

@media (max-width: 1024px) {
  .expand-coverPic {
    display: none;
  }
  .tt-cover-image {
    top: -12px !important;
  }
  .tt-noticeDisplay {
    top: -10px !important;
    margin-top: 15px !important;
  }
  .tt-content-section {
    margin-top: -15px !important;
  }
  .tt-resNoticeCss {
    display: none;
  }
  .tt-navbar-heading-responsive {
    display: block;
  }
  .tt-navbar-heading {
    display: none;
  }
  .mobileNavbarMenu {
    display: none !important;
  }
}

// @media (max-width: 600px) {
//   .tt-menu-bar {
//     top: -35px;
//   }
// }

// @media screen and (max-width: 480px) {
//   .tt-menu-bar {
//     top: -40px;
//   }
// }

@media screen and (max-width: 414px) {
  .tt-navbar-heading-responsive {
    font-size: 20px;
  }
}

@media screen and (max-width: 414px) {
  .tt-navbar-heading-responsive {
    font-size: 18px;
  }
}

@media screen and (max-width: 320px) {
  .tt-navbar-heading-responsive {
    font-size: 16px;
  }
}
