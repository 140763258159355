@import "./variables";

@mixin style($bdr, $bgColor, $height) {
  border: $bdr;
  background-color: $bgColor;
  height: $height;
}

.tt-widget-container {
  @include style(7px solid $whiteColor, $whiteColor, 500px);
  box-shadow: 2px 3px 3px $shadowColor;
  border-radius: 10px;
  overflow: hidden;
  cursor: default;
  transition: 0.8s;
  position: sticky;
  top: 10px;
  .tt-widget-box {
    @include style(1px solid $primaryColor, $whiteColor, 430px);
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: center;
    border-radius: 5px;
    box-shadow: inset 7px 7px 10px -5px $shadowColor;
    .tt-widget-holder {
      @include style(1px solid $primaryColor, $whiteColor, 125px);
      margin: 13px;
      border-radius: 5px;
      padding: 15px;
      cursor: pointer;
      width: 144px;
      //   flex: auto;

      // flex: 1 1 100px;
      .tt-widget-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        margin: 10px;
        color: $primaryHoverColor;
      }

      .tt-widget-title {
        color: $primaryHoverColor;
        font-weight: bold;
        text-align: center;
        padding: -2px 6px;

        a {
          color: inherit;
        }
      }
      &:hover {
        box-shadow: 2px 3px 3px $shadowColor;
        background-color: $primaryHoverColor;
      }
      &:hover > .tt-widget-title {
        color: $whiteColor;
      }
      &:hover > .tt-widget-icon {
        color: $whiteColor;
      }
    }
  }
}

/*-----------------Tab Panel Width----------------*/
.rwt__tabpanel {
  width: 100%;
}

.rwt__tab::after {
  border-bottom: none !important;
}

.rwt__tab[aria-selected="true"] {
  background-color: $primaryColor !important;
  color: $whiteColor !important;
  text-shadow: 1px 1px 2px $borderColor !important;
}

.rwt__tab_main-tab {
  height: 35px !important;
  width: 180px !important;
  padding: 0 !important;
  box-shadow: 1px 0px 2px $primaryHoverColor !important;
  // border-right: 1px solid $primaryColor !important;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-right: none !important;
  }
  h6 {
    font-size: 13px;
  }
}

.rwt__tab:hover {
  background-color: $primaryHoverColor !important;
  color: $whiteColor;
  text-shadow: 1px 1px 2px $borderColor;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.new-row {
  visibility: visible;
  opacity: 1;
  animation: slide-down 0.5s ease-out;
}

/*----------------------For BreadCrumbs---------------------*/
.tt-breadCrumbs {
  background-color: $breadCrumbColor;
  color: $whiteColor;
  text-shadow: 1px 1px 3px $borderColor;
  padding: 5px 10px;
  margin-bottom: 5px;
  font-size: 13px;
  font-style: italic;
}
.minimize {
  height: 55px !important;
}
// .modules-container {
//   overflow-x: auto;
// }

.modal-body {
  //   max-width: 88vw !important;
  .file-preview-col {
    max-height: 70vh !important;
    min-height: 70vh !important;

    overflow-y: scroll;
  }
  .file-list-col {
    max-height: 70vh !important;
    // min-height: 70vh !important;
    overflow: scroll;
    overflow-x: hidden;
  }

  .download-col {
    background: $primaryColor;
    margin-bottom: -42px;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-weight: bold;
    // text-transform: uppercase;
    text-shadow: 1px 2px 2px $shadowColor;
    h6 {
      color: #fff;
      font-size: 20px;
      margin-bottom: 0;
    }
    li {
      word-wrap: break-word;
    }
  }
}
.activeIndex {
  color: #7798ed;
}
