@import "./variables";

.tt-subjectAreaContent {
  div {
    // margin-top: 5px;
    .tt-subjectAreaButtons {
      float: right;
      margin-bottom: 10px;
    }
    div > label {
      font-weight: bold;
      line-height: 2;
    }
  }
}

.tt-subjectAreaContentInput {
  div {
    margin-top: 5px;
  }
}
