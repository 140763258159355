.heading-03 {
  color: $purple;
  margin-bottom: 18px;
}

.heading-06 {
  color: $purple;
}
.videoOverlay {
  background-color: rgba(0, 0, 0, 0.33);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

.videoPlayIcon {
  color: #bb2827;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 40px;
  position: absolute;
  z-index: 10;
}

.horizontal-line {
  width: 8%;
  border: 2px solid $purple;
}

.popUpHover:hover {
  // padding: 1%;
  // filter: url(#drop-shadow);
  transform: scale(1.3);
  // box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  // -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
  // filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
  filter: hue-rotate(120deg);
}

.training-container {
  ::-webkit-scrollbar {
    display: none;
  }
}

.trainingVideoBox {
  cursor: pointer;
  padding: 8px !important;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: auto !important;
}
.border-black-right {
  border-right: 1px solid #ccc;
}

.trainingScrollable {
  height: 360px;
  overflow-y: auto;

  .manualFiles-item {
    &:hover {
      cursor: pointer;
    }
  }
}

.icon-image {
  height: 70px;
  text-align: center;
  width: auto;
  img {
    height: 100%;
    // width: 100%;
    object-fit: cover;
  }
}

.new-height {
  height: 90px !important;
  border-radius: 50%;
  width: 90px;
  overflow: hidden;
  // box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
  //   0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  margin-left: auto;
  margin-right: auto;
  // margin-bottom: 8px;
}

.back-shadow {
  background-color: #f1f9fc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  overflow: hidden;
}

.white-background {
  background-color: #ffffff;
  padding: 5rem 0;
  width: 100% !important;
}

.cdc-center {
  // display: flex;
  // flex-direction: column;
  align-items: center;
  // justify-content: center;
  // height: 450px;
}

.current-training-image {
  // margin-top: 1.5rem;
  height: auto;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.card {
  // width: 240px;
  height: auto;
  background: #ffffff 0% 0%;
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
    0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  border-radius: 0.75rem !important;
  opacity: 1;
  padding: 5px;
  border: none !important;
}

.carousel-setting {
  img {
    height: 450px;
    width: 100%;
    object-fit: cover;
  }
  .carouselCaption {
    position: absolute;
    left: 10%;
    bottom: 9%;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 15px 10% 15px 30px;
    width: 55%;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
      0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  }
}
.truncateLines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 70px;
}
.truncateLines-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  // height: 0px;
  p {
    font-size: inherit !important;
    color: black !important;
    font-weight: normal !important;
  }
}

.tt-topicHolder {
  border: 1px solid #ccc;
  padding: 13px;
  height: 75vh;
  overflow: auto;
}
.backGround {
  background-color: #ffffff;
  z-index: 1;
}

.dotsRender:before {
  position: absolute;
  right: 0;
  top: 0;
  width: 95%;
  font-weight: normal;
  overflow: hidden;
  content: " ................................................................................................................................................................................................................................................................................................................. ";
}

.searchBar {
  background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);
  margin: 10px 0;
  padding: 10px;
  color: white;
  font-weight: 400;
  span {
    font-weight: bold;
  }
}
