@import "../variables";

.tt-mobileVersion {
  display: none;
}
@media (max-width: 1024px) {
  body,
  #root {
    height: 100%;
  }

  .modal {
    height: auto !important;
     .modal-dialog{
      height: 100vh !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .tt-desktopVersion {
    display: none;
  }
  .tt-mobileVersion {
    display: block;
  }
  .tt-rememberMe {
    color: $borderColor;
    cursor: pointer;
    text-shadow: none;
    font-weight: 600;
    position: relative;
    left: 5px;
    top: -1px;
  }

  .mobileBack {
    // background-image: url(/educationDemo/static/media/plainBackground.3d93ebf4.jpg);
    background-position: center;
    background-size: cover;
    height: 100%;
    background-repeat: no-repeat;
    background-color: $whiteColor;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .handleMarginMobile {
      margin-top: -41px;
    }
  }

  .tt-mobile-loginLogo-holder {
    padding: 10px;
    // margin-top: 65px;
    position: relative;
    top: 41px;
    .tt-mobile-login-logo {
      width: 60%;
      background-color: $whiteColor;
    }
  }

  .tt-mobile-loginBox {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 20px;
    width: 75%;
    box-shadow: 2px 3px 3px $editButtonColor;
    display: block;
    margin-left: auto;
    background-color: $whiteColor;
    margin-right: auto;
    div {
      //   background: $primaryColor;
      padding: 15px;
      margin-top: 35px;
    }
  }

  .tt-forgot-password-mobile {
    cursor: pointer;
    // color: $borderColor;
    color: #202020;
    text-shadow: none;
    -webkit-user-select: none;
    user-select: none;
    margin-bottom: 0;
    margin-top: 30px;
    // font-weight: 600;
    font-weight: 300 !important;
  }
}

@media screen and (max-width: 480px) {
  .forgotModal {
    margin: 20px !important;
  }
}

@media (max-width: 320px) {
  .tt-mobile-loginLogo-holder {
    top: 30px;
  }
  .tt-mobile-loginBox {
    div {
      margin-top: 15px;
    }
  }
}
