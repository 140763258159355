@import "./variables";

.tab-header {
  padding: 10px;
  margin-bottom: 7px;
  background-color: $primaryColor;
  font-weight: bold;
  letter-spacing: 0.5px;
  cursor: default;
  color: $whiteColor;
  text-shadow: 1px 1px 2px $borderColor;
  user-select: none;
}

.attendance-button {
  margin-left: 60px !important;
}

.holiday-date {
  border: 2px solid #ff8f8f !important;
}

.canvas-size {
  height: 400px !important;
  width: 400px !important;
  margin: auto;
  // margin-left: 4.5rem !important;
}
.custom-canvas-size {
  height: 200px !important;
  width: 200px !important;
  margin: auto;
  // margin-left: 4.5rem !important;
}
.line-canvas-size {
  height: 600px !important;
  width: 800px !important;
  margin: auto;
  // margin-left: 9.5rem !important;
}

.stc-canvas-size {
  height: 300px !important;
  width: 300px !important;
}

.total-days {
  background-color: #577aa3;
}

.present-days {
  background-color: #4db14d;
}

.absent-days {
  background-color: #bd5151;
}

.filter-header {
  background-color: $primaryColorDark;
  color: $whiteColor;
  padding: 5px;
}

.index-info {
  background-color: $indexInfo;
  color: $borderColor;
  padding: 0;
  position: sticky;
  top: -8px;
  z-index: 100;
}
