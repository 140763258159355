@import "./variables";

.classRoutineCheckbox{
    position: relative;
    margin-top: 5px !important;
    margin-right: 5px !important;
}
.classRoutineLabel{
    position: relative;
    top: -2px;
    cursor: pointer;
}
.classRoutineTable{
    overflow: visible !important;
    
}
is-danger{
    border: 1px solid red;
}