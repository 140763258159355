@import "./variables";

@mixin style($bdr, $bgColor, $height) {
  border: $bdr;
  background-color: $bgColor;
  height: $height;
}

body {
  // background-color: $contentBackgroundColor !important;
  background: url("../images/silverBg3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-size: 13px;
}

a:hover {
  text-decoration: none !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: $loginBackgroundColor;
}

::-webkit-scrollbar-thumb {
  // background: $primaryColor;
  background: $gradientBg;
  border-radius: 10px;
  border: 1px solid $whiteColor;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: $primaryHoverColor;
}

.customOveflow {
  overflow: visible;
  margin-top: 50px;
  background-color: white;
}

.tt-group-header {
  padding: 10px !important;
  margin-bottom: 7px;
  // background-color: $primaryColor !important;
  background: linear-gradient(195deg, $grdColor, $primaryColor);
  font-weight: bold;
  border-radius: 5px;
  letter-spacing: 0.5px;
  cursor: default;
  color: $whiteColor;
  text-shadow: 1px 1px 2px $borderColor;
  user-select: none;
}

// @media print {
//   .tt-group-header {
//     // color: #363e44 !important;
//   }
//   #printRoutine{
//     // transform: rotate(90deg);
//     // // transform: rotate3d(1, 1, 1, 28deg);
//     // orientation: landscape;
//     // @page {
//       // size: A4 landscape !important;
//     // }
//   }
// }

@media print {

  @page landscape {
    size: landscape;
    margin-left: 10mm;
    // margin-top: 25mm;
    margin-right: 10mm;
    margin-bottom: 1mm;
    // margin: 0mm 10mm 10mm 0mm;
  }
  @page potrait {
    size: portrait;
    margin-left: 10mm;
    // margin-top: 25mm;
    margin-right: 10mm;
    margin-bottom: 1mm;
    // margin: 0mm 10mm 10mm 0mm;
  }
  .potrait {
    page:portrait;
  }
  .landscape {
    page:landscape;
  }

  #printRoutine {
    top: 0;
    width: 100vw;
    // height: 100vh;
    overflow: visible !important;
  }
  #printRoutineAdmitCard{
    margin-top: 20mm !important;
    .admitCardContainer{
      border-bottom: 1px solid #428ab4;
    }
    .admitCardContainer:nth-child(3n){
      page-break-after:always;
    }
  }
  .print-table{
    zoom: 0.7;
  }
}

.attendance-top-header {
  padding: 10px !important;
  margin-bottom: 7px;
  background-color: $primaryColor !important;
  border-radius: 5px;
  cursor: default;
  user-select: none;

  label {
    font-weight: bold;
    letter-spacing: 0.5px;
    color: $whiteColor;
    text-shadow: 1px 1px 2px $borderColor;
  }
}

.tt-group-header-dark {
  padding: 10px;
  margin-bottom: 7px;
  background-color: $primaryColorDark;
  font-weight: bold;
  border-radius: 5px;
  letter-spacing: 0.5px;
  cursor: default;
  color: $whiteColor;
  text-shadow: 1px 1px 2px $borderColor;
  //   color: $whiteColor;
}

.form-control {
  box-shadow: inset 7px 7px 10px -5px $shadowColor;
}

.form-control-warn {
  border: 1px solid red;
}

/*------------------------Buttons---------------------*/

@mixin btnColors($color, $bgColor, $bgImage) {
  color: $color !important;
  background-color: $bgColor !important;
  background-image: linear-gradient($bgColor, $bgImage) !important;
}

.tt-button {
  display: inline-block;
  position: relative;
  margin-left: auto;
  padding: 4px 20px;
  font-size: 13px;
  outline: none !important;
  border: 1px solid $whiteColor;
  border-radius: 5px;
  //   margin-top: 10px;
  @include btnColors($whiteColor, $cancelBtnColor, $cancelGradientColor);

  &:disabled {
    @include btnColors($whiteColor, $cancelBtnColor, $cancelGradientColor);

    &:hover {
      @include btnColors($whiteColor, $cancelBtnColor, $cancelGradientColor);
    }
  }

  &:hover,
  &:focus {
    background: $CancelBtnHoverColor;
  }

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: 50%;
    background: linear-gradient(rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2));
  }
}

.tt-button-primary {
  @include btnColors($whiteColor, $cehrdBtnColor, $cehrdBtnColor);

  &:hover,
  &:focus {
    background: $primaryBtnHoverColor;
  }
}

.tt-button-danger {
  @include btnColors($whiteColor, $dangerBtnColor, $dangerGradientColor);

  &:hover,
  &:focus {
    background: $dangerBtnHoverColor;
  }
}

.tt-button-fullWidth {
  width: 100%;
}

.tt-content-section {
  position: relative;
  margin-top: -50px;
}

textarea {
  resize: none;
}

.no-border-td {
  .associateLevel {
    border: none !important;
  }
}

.range-minus {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 18px;
}

/*-------------------------------Modal-----------------------*/

.tt-modal {
  .modal-content {
    // height: -webkit-fill-available;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.modal-content {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  border-radius: 10px !important;
  border: 7px solid $whiteColor !important;
}

.modal-body {
  padding: 0.5rem 0.5rem !important;
  scroll-behavior: smooth;
}

.modal-header {
  position: sticky;
  top: 0;
  z-index: 9999;
}

/*-------------------------------For Suggestion Tabs-----------------------*/

.tt-horizontal {
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
}

/*-------------------------------For Inline Display-----------------------*/

.tt-inline {
  display: inherit;
}

.tt-widgetContent-tab-holder {
  border: 7px solid $whiteColor;
  box-shadow: 1px 1px 5px $shadowColor;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 4px;
}

h6 {
  margin-bottom: 0 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// cursors
.tt-cursor-pointer {
  cursor: pointer;
}

//Form
.form-control:focus {
  box-shadow: none !important;
  border-color: $primaryColor;
}

.tt-adjustPadding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  height: 15px;
  width: 20px;
}

.card-body {
  padding: 5px !important;
  // max-height: 385px;
  // overflow-y: scroll;
}

// Chips
.MuiChip-root {
  border-radius: 5px !important;
  margin: 2px !important;
  height: auto !important;
  padding: 3px 0 !important;
}

.MuiChip-colorPrimary {
  background-color: lighten($primaryHoverColor, 30%) !important;
  color: $editButtonColor !important;
  font-weight: 600;
}

.MuiChip-label {
  white-space: pre-wrap !important;
}

.form-group {
  margin-bottom: 6px !important;
}

table {
  td {
    padding: 5px !important;
    // vertical-align: middle !important;

    p {
      display: inline;
    }
  }

  th {
    vertical-align: middle !important;
  }
}

.rwt__tabpanel {
  cursor: pointer;
}

//paddings
.tt-paddingLeft {
  padding-left: 5px;
}

.tt-paddingRight {
  padding-right: 5px;
}

.tt_align_items_v_middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* flex-wrap: wrap; */
  //   align-items: center;
}

.tt_align_items_v_top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /*Aligns vertically center */
  //   justify-content: center; /*Aligns horizontally center */
}

.tt_align_items_v_bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /*Aligns vertically center */
  //   justify-content: center; /*Aligns horizontally center */
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: $tableAlternateBgColor;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $whiteColor;
}

.table-collapse-tt {
  tbody {

    // span:nth-child(even) {
    //   background-color: rgba(119, 152, 237, 0.3) !important;
    // }
    // span:nth-child(odd) {
    //   background-color: $whiteColor !important;
    // }
    .tt-colCollapse {
      background-color: $whiteColor !important;
    }

    .tt-colRow {
      &:nth-of-type(odd) {
        background-color: $whiteColor !important;
      }
    }

    .tt-colRow {
      &:nth-of-type(even) {
        background-color: $tableAlternateBgColor !important;
      }
    }
  }
}

.tt-trDiv {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.css-1w9j89e-menu {
  z-index: 1000 !important;
}

// .widgetHolder {
//   .rwt__tablist:not([aria-orientation="vertical"]) {
//     margin-bottom: 10px !important;
//   }
// }

.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid $calendarBorderColor !important;
}

// select {
//   background-color: $primaryColor;
// }

//CalendarButtons
.fc-button {
  display: inline-block;
  position: relative;
  margin-left: auto;
  padding: 4px 20px;
  font-size: 13px;
  outline: none;
  border: 1px solid $whiteColor;
  border-radius: 5px;
  outline: none;
  //   margin-top: 10px;
  @include btnColors($whiteColor, $cancelBtnColor, $cancelGradientColor);

  &:disabled {
    @include btnColors($whiteColor, $cancelBtnColor, $cancelGradientColor);

    &:hover {
      @include btnColors($whiteColor, $cancelBtnColor, $cancelGradientColor);
    }
  }

  &:hover,
  &:focus {
    background: $CancelBtnHoverColor;
  }

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: 50%;
    background: linear-gradient(rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2));
  }
}

.fc-button-primary {
  @include btnColors($whiteColor, $primaryBtnColor, $primaryGradientColor);

  &:hover,
  &:focus,
  &:visited {
    background: $primaryBtnHoverColor;
  }
}

//SWAL MODAL DESIGn

.swal-title {
  color: $whiteColor;
  font-weight: bold;
  position: relative;
  top: 0;
  display: block;
  padding: 10px;
  padding-bottom: 10px !important;
  font-size: 20px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.5px;
  border-radius: 5px;
  background-color: $primaryColor;
  margin-top: 0 !important;
  cursor: move;
  text-shadow: 1px 1px 2px $borderColor;
}

.swal-text {
  text-align: left;
  display: block;
  color: $borderColor;
  margin: 25px 0;
}

.swal-icon {
  top: 45px;
  box-sizing: content-box;
  margin: 20px auto;
}

.swal-overlay--show-modal .swal-modal {
  border: 7px solid $whiteColor;
  border-radius: 10px;
  //   position: absolute;
}

.swal-footer {
  background-color: $primaryColor;
  padding: 5px;
  border-radius: 5px;
}

.swal-button-container {
  margin: 3px;
}

.swal-button {
  display: inline-block;
  position: relative;
  margin-left: auto;
  padding: 4px 20px;
  font-size: 13px;
  outline: none;
  border: 1px solid $whiteColor;
  border-radius: 5px;
  //   margin-top: 10px;
  @include btnColors($whiteColor, $primaryBtnColor, $primaryGradientColor);

  &:not([disabled]):hover,
  &:focus {
    background-color: $primaryBtnHoverColor !important;
    box-shadow: none !important;
  }

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: 50%;
    background: linear-gradient(rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2));
  }
}

//swal-modal-design 2

.swal2-popup {
  padding: 0 !important;
  border: 7px solid $whiteColor !important;
  border-radius: 10px !important;
}

.swal2-header {
  background-color: $primaryColor;
  align-items: flex-start !important;
  border-radius: 5px;
  text-align: left !important;

  .swal2-title {
    color: $whiteColor;
    font-weight: bold;
    position: relative;
    top: 0;
    display: block;
    padding: 10px;
    padding-bottom: 10px !important;
    font-size: 20px;
    line-height: 20px;
    text-align: left;
    letter-spacing: 0.5px;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    cursor: move;
    text-shadow: 1px 1px 2px $borderColor;
  }
}

.swal2-content {
  text-align: left !important;
  display: block;
  color: $borderColor !important;
  margin: 5px 0 !important;
  /* font-size: 23px; */
  padding-left: 10px !important;

  .swal2-html-container {
    p {
      margin-bottom: 0 !important;
      font-size: 15px;
    }

    ol {
      padding-left: 15px;
      margin-bottom: 0 !important;
    }
  }
}

.swal2-actions {
  background-color: $primaryColor;
  padding: 5px;
  border-radius: 5px;
  margin-top: 0 !important;



  .swal2-confirm {
    border-left-color: white !important;
    border-right-color: white !important;
    display: inline-block !important;
    position: relative !important;
    margin-left: auto !important;
    padding: 4px 20px !important;
    font-size: 13px !important;
    outline: none !important;
    border: 1px solid $whiteColor !important;
    border-radius: 5px !important;
    //   margin-top: 10px;
    @include btnColors($whiteColor, $primaryBtnColor, $primaryGradientColor);

    &:not([disabled]):hover,
    &:focus {
      background-color: $primaryBtnHoverColor !important;
      box-shadow: none !important;
    }

    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: calc(100% - 4px);
      height: 50%;
      background: linear-gradient(rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.2));
    }
  }

  .swal2-cancel {
    display: inline-block;
    position: relative;
    // margin-left: auto;
    padding: 4px 20px;
    font-size: 13px;
    outline: none;
    border: 1px solid $whiteColor;
    border-radius: 5px;
    //   margin-top: 10px;
    @include btnColors($whiteColor, $primaryBtnColor, $primaryGradientColor);

    &:not([disabled]):hover,
    &:focus {
      background-color: $primaryBtnHoverColor !important;
      box-shadow: none !important;
    }

    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: calc(100% - 4px);
      height: 50%;
      background: linear-gradient(rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.2));
    }
  }
}

//Printing
.tt-printPreview {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background: $whiteColor;
  box-shadow: 2px 3px 3px $editButtonColor;
}

img {
  user-select: none;
}

label {
  user-select: none;
}

button {
  user-select: none;
}

.swal-modal {
  user-select: none;
}

.__react_component_tooltip.type-dark {
  color: $whiteColor;
  background-color: $tooltipColor !important;
}

.__react_component_tooltip.type-dark.place-top:after {
  border-top-color: $tooltipColor !important;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: $calendarBorderColor;
}

.fc-sat {
  color: $calendarHolidayColor;
  font-weight: bold;
  // background-color: $calendarBorderColor;
}

.listedTeachers {
  span:last-child {
    span:last-child {
      display: none;
    }
  }
}

.tt-infoTable {
  position: relative;
  box-shadow: 2px 3px 3px $shadowColor;
}

.schoolInformation {
  height: 61.8vh;
  overflow-y: auto;
  overflow-x: hidden;
}

//Picture holders
.tt-ppHolder {
  width: 55px;
  height: 55px;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  box-shadow: 2px 3px 3px $shadowColor;

  img {
    width: 55px;
    height: 55px;
    object-fit: cover;
  }
}

.tt-ppName {
  width: 140px;
  display: inline-block;
  overflow-wrap: break-word;
}

.tt-pp {
  display: flex;
  flex-direction: row;
  align-items: center;
}

iframe {
  background-color: $borderColor;
}

a {
  color: $borderColor;

  &:hover {
    text-decoration: none;
  }
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

.alignContent {
  text-align: right;
  padding-top: 6px;
}

table thead tr th {
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  background-color: $primaryColor;
  z-index: 90;
}

select.form-control[multiple] {
  height: 120px;
}

.css-26l3qy-menu {
  z-index: 1000 !important;
}

.tt-peopleList {
  .modal-body {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

select {
  box-shadow: inset 7px 10px 10px -5px $shadowColor;
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
  color: $whiteColor;
  background-color: $primaryBtnHoverColor;
  border-color: $primaryBtnHoverColor;
}

.tt-listTable {
  thead tr th {
    position: sticky;
    position: -webkit-sticky;
    top: 34px;
    background-color: $primaryColor;
    z-index: 100;
  }
}

.bootstrap-select {
  .dropdown-toggle {
    border: 1px solid #ccc;
  }
}

.navLogo {
  // width: 70px;
  width: auto;
  // height: 40px;
  height: 95px;
  margin-right: 10px;
  width: 100px;
  object-fit: contain;
}

.disclaimerBox {
  // background-color: $indexInfo;
  background-color: #003594;
  padding: 8px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  color: $whiteColor;
}

// :not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
//   width: 120px;
// }
.bootstrap-select .dropdown-menu li a span.text {
  border: none !important;
}

.tt-newListBox {
  border: 1px solid #ccc;
  padding: 5px 15px;
  height: 69.6vh;

  overflow: auto;

  table thead tr th {
    top: -8px !important;
  }

  .tt-stickyHeader {
    top: -5px !important;
  }
}

.tt-admissionListBox {
  border: 1px solid #ccc;
  padding: 5px;
  height: 69.6vh;
  overflow: auto;

  table thead tr th {
    top: 25px !important;
  }
}

.tt-smallListBox {
  border: 1px solid #ccc;
  padding: 5px;
  height: 36.4vh;
  overflow: auto;

  table thead tr th {
    top: -8px !important;
  }
}

//Widget Spinner
.tt-widgetSpinner {
  position: fixed;
  background-color: $shadowColor;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  justify-content: center;
  align-items: center;
  left: 0;

  .spinner-border {
    width: 5rem;
    height: 5rem;
  }
}

.tt-chip-red {
  margin-left: 10px;
  display: inline-block;
  padding: 0 12px;
  height: 20px;
  font-size: 10px;
  line-height: 20px;
  border-radius: 25px;
  background-color: #a70707;
  color: white;
  font-weight: 600;
}

.tt-chip-green {
  font-weight: 600;
  color: white;
  margin-left: 10px;
  display: inline-block;
  padding: 0 12px;
  height: 20px;
  font-size: 10px;
  line-height: 20px;
  border-radius: 25px;
  background-color: green;
}

.permissionBtnCSS {
  top: -4px;
}

#tt-permissionModal {
  .modal-content {
    overflow: unset !important;
  }
}

.postQuestions {
  max-height: 68vh;
  overflow-x: hidden;
  overflow-y: auto;
}

input::-webkit-calendar-picker-indicator {
  opacity: 1;
}

//For error Display

.tt-errorBackground {
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .tt-errorMessageHolder {
    background: $whiteColor;
    border: 7px solid $whiteColor;
    border-radius: 6px;
    width: 500px;
    box-shadow: 2px 3px 3px $shadowColor;
  }
}

.tt-assignSearchDropdown {
  background-color: $primaryColor;
  text-shadow: 1px 1px 2px $borderColor;
  color: $whiteColor;
  padding: 3px;
}

@media screen and (max-width: 700px) {
  .mobileWidgetArea {
    display: none;
  }
}

@media screen and (max-width: 1366px) {
  .rwt__tabpanel {
    overflow-x: auto;
  }

}


@media screen and (max-width: 600px) {
  .reverseContent {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 360px) {
  .tt-button {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .tt-button {
    font-size: 10px;
  }
}

input {
  overflow: hidden !important;
}

/**global snackbar override**/
.MuiSnackbarContent-root {
  background-color: #7798ed !important;
  color: rgb(0, 0, 0) !important;
}

//added 1/1 for valdiation indication
.indicate-error {
  border: 1px solid $dangerBtnColor !important;
}

.optional {
  color: $optional !important;
}

.assignmentDescription {
  border-style: outset;
  border-width: 2px;
  border-color: $primaryColor;
}

//for image file viewer
.pg-viewer-wrapper {
  // overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container>img {
  width: unset !important;
  height: unset !important;
}

.pdf-canvas {
  canvas {
    display: block;
    margin: auto;
  }
}

.fullModal {
  min-width: 90vw !important;
  // height: 80vh !important;
  // max-width: none;

  .modal-body {

    //   max-width: 88vw !important;
    .file-preview-col {
      max-height: 70vh !important;
      min-height: 70vh !important;
      overflow-y: scroll;
    }

    .file-list-col {
      max-height: 70vh !important;
      min-height: 70vh !important;
      overflow: scroll;
      overflow-x: hidden;
    }

    .download-col {
      background: $primaryColor;
      margin-bottom: -42px;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      font-weight: bold;
      // text-transform: uppercase;
      text-shadow: 1px 2px 2px $shadowColor;

      h6 {
        color: #fff;
        font-size: 20px;
        margin-bottom: 0;
      }
    }
  }

  .activeIndex {
    color: #7798ed;
  }
}

//Information
.tt-profile-assignment {
  max-height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
}

.tt-inventoryItemHolder {
  border: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.myCss {
  option:disabled {
    color: #ccc;
  }
}

// .switch {
//   position: relative;
//   display: inline-block;
//   width: 35px;
//   height: 22px;
//   margin: 5px;
//   input {
//     opacity: 0;
//     width: 0;
//     height: 0;
//   }

//   .slider {
//     position: absolute;
//     cursor: pointer;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: #ccc;
//     -webkit-transition: 0.4s;
//     transition: 0.4s;
//   }

//   .slider:before {
//     position: absolute;
//     content: "";
//     height: 14px;
//     width: 16px;
//     bottom: 4px;
//     background-color: white;
//     -webkit-transition: 0.4s;
//     transition: 0.4s;
//   }

//   input:checked + .slider {
//     background-color: $primaryBtnColor;
//   }

//   input:focus + .slider {
//     box-shadow: 0 0 1px $primaryBtnColor;
//   }

//   input:checked + .slider:before {
//     -webkit-transform: translateX(16px);
//     -ms-transform: translateX(16px);
//     transform: translateX(16px);
//   }

//   /* Rounded sliders */
//   .slider.round {
//     border-radius: 34px;
//   }

//   .slider.round:before {
//     border-radius: 50%;
//   }
// }
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: $primaryHoverColor;
}

input:focus+.slider {
  box-shadow: 0 0 1px $primaryHoverColor;
}

input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media print {
  .printWorkSheet {
    overflow: hidden;
    padding: 100px !important;
  }

  .printPage {
    @page {
      size: landscape;
      margin-bottom: 30px;
    }

    // height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .printPlanner {
    @page {
      size: landscape;
    }

    // overflow: hidden;
    height: 0;
    size: landscape;
    margin: 0 !important;
    padding: 100px !important;

    table {
      border: 2px solid black !important;

      thead {
        border: 2px solid black !important;

        tr {
          border: 2px solid black !important;

          th {
            border: 2px solid black !important;
          }
        }
      }

      tbody {
        tr {
          border: 2px solid black !important;

          td {
            border: 2px solid black !important;
          }
        }
      }
    }
  }
}

._3fmCl {
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

._2aNF9 {
  font-size: 16px;
  line-height: 1.5;
  padding: 2px;
  width: auto;
}

.c-pill {
  align-items: center;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
  font-weight: bold;
  font-size: 11px;
  display: inline-block;
  height: 100%;
  white-space: nowrap;
  width: auto;

  position: relative;
  border-radius: 100px;
  line-height: 1;
  overflow: hidden;
  padding: 0px 12px 0px 20px;
  text-overflow: ellipsis;
  line-height: 1.25rem;
  color: #595959;

  word-break: break-word;

  &:before {
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 6px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 10px;
  }
}

.c-pill--success {
  background: #b4eda0;
}

.c-pill--success:before {
  background: #6bc167;
}

.c-pill--warning {
  background: #ffebb6;
}

.c-pill--warning:before {
  background: #ffc400;
}

.c-pill--danger {
  background: #ffd5d1;
}

.c-pill--danger:before {
  background: #ff4436;
}

.bill-receipt-pdf {}

.payment-print {
  @media print {
    @page {
      size: landscape;
    }
  }
}

// otp page

.otp-page {
  height: 420px;
  background-color: rgb(253, 253, 253);
  display: flex;
  align-items: center;
  padding: 100px;

  .otp-img img {
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;
  }

  .otp-code {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .otp-input {
      width: 60px;
      height: 30px;

      text-align: center;
    }
  }
}

//toggle switch

// .twoFAModal {
//   /* The switch - the box around the slider */
//   .switch {
//     position: relative;
//     display: inline-block;
//     width: 60px;
//     height: 32px;
//   }

//   /* Hide default HTML checkbox */
//   .switch input {
//     opacity: 0;
//     width: 0;
//     height: 0;
//   }

//   /* The slider */
//   .slider {
//     position: absolute;
//     cursor: pointer;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: #ccc;
//     -webkit-transition: 0.4s;
//     transition: 0.4s;
//   }

//   .slider:before {
//     position: absolute;
//     content: "";
//     height: 26px;
//     width: 26px;
//     left: 4px;
//     bottom: 4px;
//     background-color: white;
//     -webkit-transition: 0.4s;
//     transition: 0.4s;
//   }

//   input:checked + .slider {
//     background-color: #2196f3;
//   }

//   input:focus + .slider {
//     box-shadow: 0 0 1px #2196f3;
//   }

//   input:checked + .slider:before {
//     -webkit-transform: translateX(26px);
//     -ms-transform: translateX(26px);
//     transform: translateX(26px);
//   }

//   /* Rounded sliders */
//   .slider.round {
//     border-radius: 34px;
//   }

//   .slider.round:before {
//     border-radius: 50%;
//   }
// }
// card coantiner card and certificates

.card-setup-container .form-group {
  margin-bottom: 14px !important;
}

// .css-2b097c-container {
//   z-index: 99;
// }

// Search CSS
.groupSearchBox {
  // background-color: #f1f9fc;
  background-color: #cdeff9;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  height: 160px;
  cursor: pointer;
  text-align: left;
  border: 4px solid white;

  &:hover {
    background-color: lightyellow;
    border: 4px solid yellow;
  }

  .groupSearchTitle {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: $primaryColor;
  }

  .groupSearchContent {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
}

.userSearchBox {
  // background-color: #f1f9fc;
  background-color: #cdeff9;
  display: flex;
  // justify-content: center;
  // flex-direction: column;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-align: left;
  border: 4px solid white;

  &:hover {
    background-color: lightyellow;
    border: 4px solid yellow;
  }

  .userImageBox {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .userSearchTitle {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: $primaryColor;
  }

  .userSearchContent {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
}

.searchTypeHolder {
  display: flex;

  .searchTypeBox {
    background-color: $primaryColor;
    color: white;
    padding: 6px 15px;
    border-radius: 25px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
}

.groupImage {
  margin: 10px;
  margin-bottom: 15px;
  margin-top: 0px;

  img {
    width: 100%;
  }
}

.tt-group-container {
  @include style(7px solid $whiteColor, $whiteColor, 462px);
  box-shadow: 2px 3px 3px $shadowColor;
  margin-top: 20px;
  border-radius: 10px;
  cursor: default;
  overflow: hidden;
  transition: height 0.8s;

  .tt-group-box {
    @include style(1px solid $primaryColor, $whiteColor, 325px);
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: center;
    border-radius: 5px;
    box-shadow: inset 7px 7px 10px -5px $shadowColor;

    .tt-group-holder {
      @include style(1px solid $primaryColor, $whiteColor, 100px);
      margin: 8px;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      width: 100px;

      &:hover {
        box-shadow: 2px 3px 3px $shadowColor;
      }

      .tt-memeber-img {
        color: $primaryHoverColor;
        font-weight: bold !important;
        text-align: center;
        height: 85%;
        padding: 0 6px;

        img {
          height: 65px;
          width: 100%;
          object-fit: cover;
          border: 1px solid #ccc;
        }
      }

      .tt-member-name {
        color: $primaryColor;
        font-weight: bold !important;
        text-align: center;
        padding: 0 6px;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.discussion-jodit {
  .jodit-wysiwyg_mode {
    min-height: 400px !important;
  }

  .jodit-workplace {
    min-height: 400px !important;
  }
}

// Profile new UI
.profileHeaderDiv {
  background-color: #eae6e6;
  position: relative;
  height: 350px;
  color: white;
  padding: 25px;
  text-align: center;
  transition: 0.3s;

  .profileSubtitle {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 300;
  }

  .profileMainTitle {
    font-size: 18px;
    font-weight: 600;
  }

  .newProfileDiv {
    position: absolute;
    bottom: -50px;
    left: 10%;
    z-index: 100;
    display: flex;
    align-items: center;

    .newProfile {
      width: 130px;
      height: 130px;
      overflow: hidden;
      border-radius: 50%;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.33);
      border: 3px solid #003594;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .newProfileName {
      font-size: 13px;
      margin-left: 5px;
    }
  }

  .profileNepalLogo {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }

  .profileNepalLogoMinimized {
    width: 75px;
    height: 75px;
    object-fit: contain;
  }

  .profileMainTitleMin {
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 600;
  }

  .profileSubtitleMin {
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: 300;
  }
}

.minimizeProfileDiv {
  height: 170px;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.newColor {
  color: #4a4545;
}

#newUserData {
  width: 100%;
}

.profileHeaderCoverPicDiv {
  height: 350px;
  transition: 0.2s;
  overflow: hidden;

  .profileSliderImg {
    height: 350px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0;
    }
  }
}

.tt-newUserDetailContainer {
  background: #e8f6ff;
  padding: 10px;
  transition: 0.3s;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .toggleCoverBtn {
    position: absolute;
    bottom: -15px;
    background: $displayBackgroundColor;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: $primaryColor;
    }
  }
}

.minimizeUserDetail {
  height: 0;
  min-height: 0;
  // overflow: hidden;
  padding: 0;
  margin-bottom: 2.5rem;
}

.tt-newUserDetailTitle {
  font-size: 19px;
  // text-shadow: 1px 1px 2px $borderColor;
  font-weight: 600;
  color: $primaryColor;
  margin-bottom: 0;

  span {
    font-weight: 500;
  }
}

.tt-new-profile-image {
  cursor: pointer;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  height: 150px;
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.33);
  border-radius: 50%;
  z-index: 1;
  overflow: hidden;
  bottom: -80px;
  border: 3px solid #003594;
  transition: 0.3s;
  left: 0;
  right: 0;
  margin: 0 auto;

  p {
    text-align: center;
    line-height: 80pt;
    font-weight: bold;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.tt-newProfile_minimized {
  height: 110px;
  overflow: hidden;
  bottom: -65px;
  transition: 0.3s;
  width: 110px;

  button {
    font-size: 11px !important;
  }
}

.smallDiv,
.mobileProfilePic {
  display: none;
}

@media screen and(max-width:1540px) {
  .smallDiv {
    display: block;
  }
}

@media screen and(max-width: 1024px) {
  .mobileGovDiv {
    display: none;
  }

  .mobileGovDiv1 {
    max-width: 100%;
    width: 100%;
    position: relative;
  }

  .mobileProfilePic {
    display: block;
    position: absolute;
    top: 120px;
    left: 5%;

    .tt-new-profile-image {
      bottom: 0;
      border: 7px solid white;
      background-color: white;
    }
  }
}

@media screen and(max-width:767px) {
  .profileHeaderCoverPicDiv {
    height: auto;
    width: 100%;

    .profileSliderImg {
      height: auto;
    }
  }

  .mobileProfilePic {
    .tt-new-profile-image {
      width: 110px;
      height: 110px;
    }
  }
}

@media screen and(max-width:493px) {
  .mobileProfilePic {
    .tt-new-profile-image {
      width: 95px;
      height: 95px;
    }
  }
}

// .mid-container{
//   height: 82vh !important;
//   overflow: scroll;
// }

.fullWindowPDF {
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  overflow: scroll;

  .closeFullPDF {
    position: fixed;
    top: 60px;
    right: 60px;
    font-size: 50px !important;
    font-weight: 900;
  }

  #pdf-controls {
    position: fixed;
    width: 100%;

    #pdf-toggle-pagination {
      display: none;
    }
  }

  #pdf-page-wrapper {
    zoom: 65%;
  }
}

.file-box {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 0.75rem;
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
    0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);

  .fileIconDiv {
    color: #4878ae;
    font-size: 25px;
  }

  .manualTitle {
    flex: 1;
    margin-left: 10px;

    p {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

#pdf-controls {
  position: absolute;
  width: 100%;

  #pdf-toggle-pagination,
  #pdf-zoom-reset {
    display: none;
  }
}

.manualViewer {
  .kVogZp {
    height: 80vh;
    overflow-y: scroll;
    zoom: 80%;
  }

  #pdf-page-wrapper {
    // zoom: 60%;
  }
}

//resource summary

.resourceCountBox {
  // text-align: center;
  // background-color: white;
  color: #4878ae;
  // padding: 10px;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 14px;
  height: 160px;
  width: 95%;
  // border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  background-color: #cdeff9;

  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
    0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  cursor: pointer;
  flex-wrap: wrap;
  text-align: center;
  transition: 0.2s;
  border: 2px solid white;

  &:hover {
    background-color: lightyellow;
    border: 4px solid yellow;

    span {
      color: $primaryColor;
      font-weight: 700;
      font-size: 1.3rem;

      color: blue;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
      // border-bottom: 1px solid blue;
    }

    .gradeImgHolder {
      width: 90%;
      height: 60%;
    }
  }

  p {
    // font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
  }

  h3 {
    font-size: 2.2rem;
    margin-bottom: 0;
  }

  .res-icon-image {
    height: 65px;
    position: absolute;
    top: -20px;
    left: 20px;

    img {
      height: 100%;
      width: 100;
      object-fit: cover;
    }
  }
}

// .unsetMenu {
//   position: unset !important;
// }

.trail-period {
  text-align: center;
  // position: fixed;
  top: 0;
  // transform: translate(0%, -50%);
  background: #aa123a;
  // height: 2rem;
  padding: 5px;
  width: 100%;
  // width: 10rem;
  z-index: 9999;
  // left: 0;
  // top: 75%;
  // display: flex;
  // align-items: center;
  // flex-direction: column;
  // justify-content: center;
  // gap: 1px;
  color: white;

  .trail-text {
    font-weight: 200;
    font-size: 12px;
  }

  .trail-time {
    font-weight: 500;
    font-size: 18px;
  }
}



._FsLy_ {
  border-radius: 0;
}


.swal2-confirm {
  display: inline-block;
  position: relative;
  margin-left: auto;
  padding: 4px 20px;
  font-size: 13px;
  outline: none;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff !important;
  background-color: #126696 !important;
  background-image: linear-gradient(#126696, #126696) !important;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.react-multi-email {
  text-shadow: none;
}

.jodit-add-new-line {
  top: 0 !important;
}

.jodit-add-new-line_after {
  top: 0 !important;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}