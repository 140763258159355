@import "./variables";

.questionHolderRepo {
  background-color: $attachFileBoxColor;
  border: 1px dashed $cancelBtnColor;
  padding: 10px;
  position: sticky;
  top: 0;
}

.tt-repoList {
  border: 1px solid $calendarBorderColor;
  padding: 5px;
  overflow-x: hidden;
  overflow-y: auto;
}

.tt-repoList {
  .tt-post-template {
    &:nth-child(odd) {
      background-color: $tableAlternateBgColor;
    }
  }
}

.imageNewDimension {
  height: 100px !important;
  border: 1px solid $primaryColor;
  border-radius: 50px;
  width: 100px !important;
}

.fullpagePP {
  width: 135px !important;
}

.imageNewDimension1 {
  height: 160px !important;
  border: 1px solid $primaryColor;
  border-radius: 80px;
  width: 160px !important;
}
