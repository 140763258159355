@import "./variables";

@mixin style($bdr, $bgColor, $height) {
  border: $bdr;
  background-color: $bgColor;
  height: $height;
}

.tt-group-container {
  @include style(7px solid $whiteColor, $whiteColor, 455px);
  box-shadow: 2px 3px 3px $shadowColor;
  margin-top: 20px;
  border-radius: 10px;
  cursor: default;
  overflow: hidden;
  transition: height 0.8s;

  .tt-group-box {
    @include style(1px solid $primaryColor, $whiteColor, 325px);
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: center;
    border-radius: 5px;
    box-shadow: inset 7px 7px 10px -5px $shadowColor;

    .tt-group-holder {
      @include style(1px solid $primaryColor, $whiteColor, 100px);
      margin: 8px;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      width: 100px;

      &:hover {
        box-shadow: 2px 3px 3px $shadowColor;
      }

      .tt-memeber-img {
        color: $primaryHoverColor;
        font-weight: bold !important;
        text-align: center;
        height: 85%;
        padding: 0 6px;
        img {
          height: 65px;
          width: 100%;
          object-fit: cover;
          border: 1px solid #ccc;
        }
      }

      .tt-member-name {
        color: $primaryColor;
        font-weight: bold !important;
        text-align: center;
        padding: 0 6px;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}



.tt-post-search-container {
  @include style(7px solid $whiteColor, $whiteColor, 300px);
  box-shadow: 2px 3px 3px $shadowColor;
  margin-top: 20px;
  border-radius: 10px;
  cursor: default;
  overflow: hidden;
  transition: 0.8s;
  height: 315px;
}

.tt-searchBtnGroup {
  left: -8px;
  top: 2px;
}

.minimize-group {
  height: 67px !important;
}
.mobileGroupHeader {
  display: none;
}

//Responsive
@media screen and (max-width: 1024px) {
  .tt-mobileViewButton {
    font-size: 12px !important;
  }
  .tt-totalMemberMobile {
    font-size: 12px;
    margin-top: 5px;
  }
  .desktopGroupHeader {
    display: none !important;
  }
  .mobileGroupHeader {
    display: block;
  }
  .tt-group-container {
    height: 495px;
  }
  .minimize-group {
    height: 64px !important;
  }
  .tt-post-search-container {
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .tt-post-search-container {
    height: auto;
    margin-top: 0 !important;
  }
  .tt-group-container{
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 480px) {
  .resGroupTitle {
    width: 18% !important;
  }
  .resGroupDropdown {
    width: auto !important;
  }
  .resGroupSearch {
    width: auto !important;
  }
  .resGroupButton {
    width: 23% !important;
  }
}

@media screen and (max-width: 414px) {
  .resGroupButton {
    width: 27% !important;
  }
}

@media screen and (max-width: 375px) {
  .resGroupButton {
    width: 29% !important;
  }
}

@media screen and (max-width: 320px) {
  .tt-mobileViewButton {
    font-size: 9px !important;
  }
  .tt-totalMemberMobile {
    font-size: 10px;
  }
  .tt-group-container {
    height: 491px;
  }
  .resGroupButton {
    width: 32% !important;
    margin-top: 4px !important;
  }
  .resGroupTitle {
    width: 24% !important;
  }
}
