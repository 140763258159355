@import "./variables";
@media print {
  .receiptDiv {
    background-color: #021156 !important;
    -webkit-print-color-adjust: exact;
    h1 {
      color: $whiteColor !important;
      -webkit-print-color-adjust: exact;
      z-index: 100 !important;
      &::first-letter {
        color: $whiteColor !important;
      }
    }
  }
  //   .invoiceBox {
  //     height: 100vh;
  //     margin: 0 !important;
  //     padding: 0 !important;
  //     overflow: hidden;
  //   }
}

.invoiceBox {
  display: flex;
  flex-direction: row;
  border: 1px solid #868686;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 11px;
  overflow: hidden;
  width: 18cm;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  .receiptDiv {
    background-color: #021156;
    width: 40px;
    padding-top: 0;
    color: $whiteColor;
    border: 1px solid #000;
    text-align: center;
    h1 {
      transform: rotate(-90deg);
      margin-top: 200px;
      letter-spacing: 5px;
    }
  }
  .receiptTableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    .receiptLogo {
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
  }
  .receiptTableBody {
    .dottedLine1 {
      border-bottom: 1.5px dotted #000;
      display: table-cell;
      width: 250px;
      margin-left: 10px;
    }
    .dottedLine2 {
      border-bottom: 1.5px dotted #000;
      display: table-cell;
      //   width: 105px;
      margin-left: 10px;
      flex: 1;
    }
    .dottedLine3 {
      border-bottom: 1.5px dotted #000;
      display: table-cell;
      flex: 1;
      margin-left: 10px;
    }
    .dottedLine4 {
      border-bottom: 1.5px dotted #000;
      display: table-cell;
      width: 320px;
      margin-left: 10px;
    }
  }
}

.receiptFooterBox {
  display: flex;
  flex-direction: row;
  .receiptTableFrame {
    thead {
      tr {
        th {
          background-color: unset !important;
        }
      }
    }
  }
  .cashierSign {
    margin-top: 25px;
    padding-top: 5px;
    border-top: 1.5px dotted #000;
    text-align: center;
    width: 120px;
  }
}
