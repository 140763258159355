@import "./variables";

@mixin fontStyle($fontColor, $fontSize) {
  text-align: center;
  color: $fontColor;
  font-size: $fontSize;
  font-weight: 600;
}

.tt-error-404 {
  @include fontStyle($borderColor, 216pt);
  line-height: 265pt;
  margin-top: 0;
  margin-bottom: 0;
}
.tt-error-title {
  @include fontStyle($borderColor, 40px);
  margin-bottom: 40px;
  margin-top: 0;
}
.tt-error-message {
  @include fontStyle($error404, 30px);
}
.tt-error-btn {
  @include fontStyle($borderColor, 16px);
  display: block;
  margin: 40px auto 0 auto;
  padding: 10px 25px;
  cursor: pointer;
  box-shadow: 2px 2px 5px $error404;
  background-color: $whiteColor;
  border: 1px solid $error404;
  &:hover {
    background-color: $borderColor;
    color: $whiteColor;
  }
  &:focus {
    background-color: unset !important;
    color: $borderColor;
  }
}

.hasAccessBox {
  background: $whiteColor;
  margin-top: 95px;
  padding: 7px;
  border-radius: 10px;
  box-shadow: 2px 3px 3px $shadowColor;
}
