@import "./variables";

.tt-termHolder {
  height: 40vh;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid $primaryHoverColor;
  padding: 10px;
}

.termErrorMessage {
  color: $dangerBtnColor;
  font-size: 11px;
  margin-bottom: 0;
  line-height: 1.5;
}

.formError {
  border: 1px solid $dangerBtnColor !important;
  &:focus {
    border: 1px solid $dangerBtnColor !important;
  }
}

.tt-divRow {
  display: table-row;
  td {
    padding: 0 !important;
  }
}
