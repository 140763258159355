@import "./variables";
.student-search {
  background-color: $primaryColor;
  color: $whiteColor;
  padding: 5px;
}

.search-form {
  background-color: $searchForm;
  padding: 5px;
}

#name-search {
  background-color: $nameSearch !important;
  color: $borderColor !important;
  outline: none !important;
  border-radius: 5px;
  padding: 2px;
  width: 100%;
}

#status-search {
  background-color: $nameSearch !important;
  color: $borderColor !important;
  outline: none !important;
  border-radius: 5px;
  padding: 2px;
}

.user-details {
  margin: 0px;
  font-family: Verdana, Tahoma, Arial, sans-serif;
  font-size: 0.9rem;
}

.index-info1 {
  background-color: $indexInfo;
  color: $borderColor;
  // padding: 0;
  position: sticky;
  top: -8px;
  z-index: 100;
}

.list-header {
  background-color: $primaryColor;
  font-weight: bold;
  font-size: 1.3rem;
  padding: 2px;
  color: $whiteColor;
  text-shadow: 1px 1px 2px $borderColor;
  letter-spacing: 1px;
  border-radius: 5px;
}
.alphabet-search-header:hover {
  background-color: $alphabetSearchHover;
}

.tt-ppDetail {
  display: flex;
  height: 350px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  div {
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 2px 3px 3px $shadowColor;
    border-radius: 5px;
    img {
      max-height: 320px;
      max-width: 528px;
      width: auto;
    }
  }
}

.detailStaffStudent {
  table {
    box-shadow: 2px 3px 3px $shadowColor;
  }
}

.page-link:active {
  background-color: $primaryColor !important;
  color: $whiteColor !important;
}
.page-item.active .page-link {
  background-color: $primaryHoverColor !important;
  border: none !important;
  color: $whiteColor !important;
}

.border-bottom {
  margin-top: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
