@import "./variables";
// * {
//   margin: 0px;
//   padding: 0px;
//   box-sizing: content-box;
// }

.cardTemplateContainer {
  //   height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //   background-color: #e6ebe0;
  flex-direction: row;
  flex-flow: wrap;
  padding: 3rem;
}

.front {
  height: 4.134in;
  width: 2.525in;
  position: relative;
  border-radius: 10px;
}

.top {
  height: 30%;
  width: 100%;
  //   background-color: #8338ec;
  background: linear-gradient(195deg, #3e39f0, #9b99de);
  position: relative;
  z-index: 5;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  img {
    height: 100px;
    width: 100px;
    background-color: #e6ebe0;
    border-radius: 10px;
    position: absolute;
    top: 60px;
    left: 75px;
  }
}

.bottom {
  height: 70%;
  width: 100%;
  background-color: white;
  position: relative;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  .schoolCardAddress {
    font-size: 12px !important;
    margin-bottom: 10px;
    top: 0;
  }
  .schoolCardName {
    margin-bottom: 3px;
    font-weight: bold !important;
    line-height: 16px;
    top: 0;
    margin-top: 10px;
  }
  .schoolInfo {
    position: absolute;
    bottom: 0;
  }
  p {
    position: relative;
    top: 60px;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 22px;
    text-emphasis: spacing;
  }
  .desi {
    font-size: 16px;
    color: grey;
    font-weight: normal;
  }
  .no {
    font-size: 15px;
    font-weight: normal;
  }
}

.barcode {
  text-align: center;
  position: relative;
  //   top: 70px;
  img {
   height: 60px;
    width: 30%;
    text-align: center;
    margin: 5px;
    object-fit: contain;
  }
}

.back {
  height: 4.134in;
  width: 2.525in;
  border-radius: 10px;
  //   background-color: #8338ec;
  background: linear-gradient(195deg, #3e39f0, #9a98e1);
  position: relative;
  .schoolData {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    .webAddress {
      color: white;
      margin-top: 18px;
      text-align: center;
      line-height: 8px;
      font-size: 12px;
    }
    .validDateCss {
      color: white;
      font-size: 12px;
      margin: 0;
    }
  }
}
.qr img {
  height: 80px;
  width: 100%;
  margin: 20px;
  background-color: white;
}
.Details {
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 25px;
}

.details-info {
  color: white;
  text-align: left;
  padding: 5px;
  line-height: 20px;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
  line-height: 22px;
  p {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 18px;
  }
  .signatureBox {
    position: relative;
    margin-top: 50px;
    .signatureDiv {
      top: -28px;
      position: absolute;
      width: 100px;
      height: 40px;
      left: 65px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    p {
      font-size: 12px;
    }
  }
}

.logo {
  height: 40px;
  width: 150px;
  padding: 40px;
}

.logo img {
  height: 100%;
  width: 100%;
  color: white;
}
.padding {
  padding-right: 20px;
}

// Template 2

.schoolWeb {
  position: absolute;
  top: 27.5px;
  left: 8px;
  color: white;
}
.template2Container {
  //   height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //   background-color: #e6ebe0;
  flex-direction: row;
  flex-flow: wrap;
  padding: 3rem;
  .template2Front {
    height: 4.134in;
    width: 2.525in;
    position: relative;
    border-radius: 10px;
    background: url("../images/cardTemplates/bgecard.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }
  .cardInfoHolder {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 60px;
    .cImgHolder {
      height: 90px;
      width: 90px;
      //   background-color: #e6ebe0;
      border-radius: 10px;
      padding: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    .cDetailHolder {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;
      .cDetailName {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 0;
        margin-bottom: 5px;
      }
      .cClassDetail {
        font-size: 13px;
        color: white;
        margin-bottom: 0;
      }
    }
  }

  .template2top {
    height: 30%;
    width: 100%;
    //   background-color: #8338ec;
    // background: linear-gradient(195deg, #3e39f0, #9b99de);
    position: relative;
    z-index: 5;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    img {
      height: 100px;
      width: 100px;
      background-color: #e6ebe0;
      border-radius: 10px;
      position: absolute;
      top: 60px;
      left: 75px;
    }
  }
  .template2Bottom {
    height: 70%;
    width: 100%;
    // background-color: white;
    position: relative;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    .schoolCardAddress {
      font-size: 12px !important;
      margin-bottom: 10px;
      top: 0;
    }
    .schoolCardName {
      margin-bottom: 3px;
      font-weight: bold !important;
      line-height: 16px;
      top: 0;
      margin-top: 10px;
    }
    .schoolInfo {
      position: absolute;
      bottom: 110px;
    }
    p {
      position: relative;
      top: 60px;
      text-align: center;
      text-transform: capitalize;
      font-weight: bold;
      font-size: 22px;
      text-emphasis: spacing;
    }
    .desi {
      font-size: 16px;
      color: grey;
      font-weight: normal;
    }
    .no {
      font-size: 15px;
      font-weight: normal;
    }
  }

  .template2Back {
    height: 4.134in;
    width: 2.525in;
    border-radius: 10px;
    background: url("../images/cardTemplates/bgecard.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    position: relative;
    .details-info {
      margin-top: 55px;
      .signatureBox {
        margin-top: 60px;
        p {
          color: black !important;
        }
      }
    }
    .schoolData {
      position: absolute;
      bottom: 50px;
      text-align: center;
      width: 100%;
      .webAddress {
        color: black;
        margin-bottom: 5px;
        text-align: center;
      }
      .validDateCss {
        color: black;
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }
}

// template3

.template3Container {
  //   height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //   background-color: #e6ebe0;
  flex-direction: column;
  //   flex-flow: wrap;
  padding: 3rem;
  .topGraident {
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    .topGraidentDiv1 {
      flex: 1;
      background-color: $indexInfo;
      clip-path: polygon(0% 0%, 74.7% 0%, 100% 100%, 0% 100%);
    }
    .topGraidentDiv2 {
      flex: 2;
      background-color: $primaryColor;
      color: white;
      text-align: center;
    }
  }
  .frontTemplate {
    width: 4.134in;
    height: 2.525in;
    position: relative;
    border-radius: 10px;
    background-color: $primaryColor;
    .cardTemplateContent {
      background-color: white;
      .contentTitleFlexDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        .contentTileLogo {
          padding: 5px 10px 5px 10px;
          img {
            width: 45px;
            height: auto;
          }
        }
        .contentTitleData {
          flex: 1;
          .contentTitleSchoolName {
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
          }
          p {
            margin-bottom: 0;
            font-size: 12px;
            line-height: 13px;
          }
        }
      }

      .contentDataFlexDiv {
        display: flex;
        margin-top: 10px;
        .contentDataDiv {
          flex: 1;
          padding-left: 1rem;
          .idTemplate {
            font-size: 11px;
            margin-bottom: 5px;
          }
        }
        .contentDataDiv2 {
          padding-right: 1rem;
          .idImageHolder {
            width: 80px;
            height: 80px;
            border: 1px solid #ccc;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .idSignature {
            font-size: 10px;
            text-align: center;
            margin-top: 10px;
          }
        }
      }
    }
    .idValidDate {
      color: white;
      text-align: center;
      p {
        font-size: 12px;
      }
    }
  }
  .backTemplate {
    width: 4.134in;
    height: 2.525in;
    border-radius: 10px;
    background-color: $primaryColor;
    // background: linear-gradient(195deg, #3e39f0, #9a98e1);
    position: relative;
    .backNote {
      padding: 1rem;
      color: $primaryColor;
      background: white;
      p {
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }

      .warningNoteBack {
        color: $calendarHolidayColor;
        font-size: 16px;
        span {
          font-size: 10px;
        }
      }
      .backSchoolTitle {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .backSchoolData {
        margin-bottom: 7px;
        color: black;
      }
    }
    .schoolData {
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 100%;
      .webAddress {
        color: white;
        margin-bottom: 10px;
        text-align: center;
      }
      .validDateCss {
        color: white;
        font-size: 12px;
      }
    }
  }
}


.idSignatureHolder {
  width: 80px;
  height: 30px;
  border: 1px solid #ccc;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}