@import "./variables";

.counter {
  margin-top: 0px !important;
  margin-left: unset !important;
}

.background {
  background-color: "#C0C0C0";
}

.tt-overallAssType {
  padding: 10px 10px 5px 10px;
  border-top: 1px solid $primaryHoverColor;
  &:nth-child(even) {
    background-color: $tableAlternateBgColor;
  }
}

.tt-GeneralAssessmentControl {
  background: $primaryColor;
  margin-left: -11px;
  margin-right: -11px;
  border: 1px solid $primaryHoverColor;
  border-top: none;
  padding: 5px;
}

.tt-generalTableContainer {
  //   height: 30vh;
  overflow-x: auto;
  overflow-y: auto;
  border: 1px solid #ccc;
  margin: 0px;
  padding: 5px;
  margin-bottom: 10px;
}

.tt-customTableContainer {
  //   height: 20vh;
  border: 1px solid #ccc;
  margin: 0px;
  padding: 5px;
  margin-bottom: 10px;
}

.tt-generalDiscipline {
  background: $primaryColor;
  padding-bottom: 5px;
  padding-top: 5px;
}

.tt-buttonGeneral {
  display: block;
  margin-right: auto;
  width: 100%;
  margin-top: 5px !important;
}

.tt-general-assessment-spinner {
  display: flex;
  justify-content: center;
}

.tt-min-max-input {
  border: none !important;
  font-style: italic;
  width: 50px;
  padding: 8px;
}

.tt-min-max-input:focus {
  text-decoration: none !important;
  border: none !important;
  outline: none !important;
}

.tt-min-max-input::-webkit-outer-spin-button,
.tt-min-max-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tt-min-max-input-highlight {
  border: 1px solid -internal-light-dark-color($borderColor, $whiteColor);
  font-style: italic;
  width: 100px;
  padding: 8px;
}

.tt-min-max-input-highlight:focus {
  text-decoration: none !important;
  //   border: none !important;
  outline: none !important;
}

.tt-min-max-input-highlight::-webkit-outer-spin-button,
.tt-min-max-input-highlight::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tt-input-comment-label-name-highlight {
  border: 1px solid -internal-light-dark-color($borderColor, $whiteColor);
}

.tt-input-comment-label-name {
  border: none !important;
}

.tt-input-comment-label-name-highlight:focus {
  outline: none !important;
}

.tt-min-max-to {
  margin: 8px;
}

.tt-min-max-select {
  margin: 10px;
}

.tt-discipline-input {
  border: none !important;
  text-align: center;
}

.tt-discipline-input:focus {
  outline: none !important;
}

.tt-discipline-input-highlight {
  border: 1px solid -internal-light-dark-color($borderColor, $whiteColor);
  text-align: center;
}

.tt-discipline-input-highlight:focus {
  outline: none !important;
}

.tt-assessment-type-table {
  overflow-y: auto;
  height: 38vh;
  border: 2px solid $primaryHoverColor;
  padding-top: 5px;
}

.tt-custome-assessment-header-input {
  width: 50% !important;
}

.tt-subjectScaleRightBorder {
  overflow-y: auto;
  height: 38vh !important;
  padding: 0;
  background-color: $whiteColor;
}

.tt-save-setting-button {
  margin: 0 auto;
}

.tt-general-assessment-classroom-list {
  display: flex;
  align-items: center;
}

.tt-general-assessment-classroom-list-header {
  font-weight: 600;
  font-size: 13px;
  padding: 3px 15px;
  background: $ttGeneralGreen;
  color: $whiteColor;
  float: right;
  border-radius: 20px;
  // margin-top: -3px;
}

.tt-general-assessment-subject-list-header {
  font-weight: 600;
  font-size: 13px;
  padding: 3px 15px;
  background: $ttGeneralGreen;
  color: $whiteColor;
  float: right;
  margin-top: -1px;
  border-radius: 25px;
}

.tt-general-assessment-classroom-list-subheader {
  font-weight: bold;
  font-size: 11px;
  padding: 5px;
}

.tt-student-list-header {
  font-weight: bold;
  font-size: 15px;
}

.tt-student-list-subheader {
  font-weight: bold;
  font-size: 11px;
  padding: 6px;
}

// .tt-student-list {
//     display: flex;
// }

.tt-rubric-description {
  opacity: 0.5;
}

// .jodit_tooltip {
//   display: none;
// }
.jodit_tooltip {
  position: absolute !important;
  background: $primaryHoverColor !important;
}

.tt-general-report-submit-div {
  display: flex;
  justify-content: center;
}

.tt-disable-comment-box {
  pointer-events: none;
  background-color: #dddddd;
  color: grey;
}
