@import "../variables";

.mobileCreatePost {
  display: none;
}
@media screen and (max-width: 1024px) {
  .tt-mobilePostCheckbox {
    display: none !important;
  }

  .mobileResultDisplay {
    display: none !important;
  }
  .mobileCreatePost {
    display: block;
  }
}
