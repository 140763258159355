@import "./variables";

.tt-calendarBox {
  height: auto;
  //overflow-y: auto;
  //overflow-x: hidden;
}

.minimize {
  height: 55px !important;
}

.tt-calendarBox {
  .fc-event-container {
    // height: 20px !important;
  }
}

.tt-calendar-container {
  height: 550px !important;
}

//Responsive
@media screen and (max-width: 1024px) {
  .todayCalendar {
    display: none !important;
  }
  .tt-calendar-container {
    position: sticky;
    top: 0;
    height: auto !important;
  }
  #tt-calendarContainer {
    margin-top: 15px !important;
  }
}

@media screen and (max-width: 580px) {
  .calendarGOTODATE {
    max-width: 68% !important;
  }
  .calendarGOTODATEBUTTON {
    max-width: 32% !important;
  }
}

@media screen and (max-width: 414px) {
  .calendarGOTODATE {
    max-width: 60% !important;
  }
  .calendarGOTODATEBUTTON {
    max-width: 40% !important;
  }
}

@media screen and (max-width: 375px) {
  .calendarGOTODATE {
    max-width: 55% !important;
  }
  .calendarGOTODATEBUTTON {
    max-width: 45% !important;
  }
}

@media screen and (max-width: 360px) {
  .tt-calendarBox {
    .fc-unthemed {
      .fc-toolbar {
        .fc-center {
          h2 {
            font-size: 18px;
          }
        }
      }
    }
  }
}
