@import "./variables";

.scale-dropdown {
  margin-top: 45px;
}

.scale-name {
  margin-top: 20px;
}

.ttgrade-range-value-list {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: $whiteColor;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: inset 7px 7px 10px -5px $shadowColor;
}

.scale-type-label {
  width: 110px;
}
.scale-content {
  display: flex !important;
}

.ttadd-title {
  text-align: center;
  margin-top: 15px;
  font-weight: bold;
  margin-bottom: 0;
}

.scale-name-field {
  &:focus {
    border-color: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-color: $primaryHoverColor;
  }
}

.inside-content {
  margin-top: 20px !important;
  border: 1px solid $primaryHoverColor;
  padding: 5px;
  margin-bottom: 10px;
}

.min-level-label {
  width: 180px;
}
.score-radio-button {
  margin: 4px;
  margin-left: 15px;
}

.aggregate-checkbox {
  margin: 6px;
}

.aggregation-per-label {
  margin-left: 60px;
}

.record-button {
  display: flex;
  margin: 15px;
  text-align: right;
}

.ttInput-field:focus {
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-color: $primaryHoverColor;
}

.ttInput-field-dropdown {
  padding: 0px !important;
}

.indicator-descriptor-dropdown-label {
  width: 120px;
}

.dropdown-content {
  padding: 16px;
  margin-bottom: 5px;
}

.range-dropdown {
  margin-left: 20px !important;
}

.assess-record-dropdown:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: $primaryHoverColor;
}

.indicator-descriptor-table {
  cursor: pointer;
}

.ttindicator-image-field-label {
  position: relative !important;
  overflow: hidden;
  margin-top: 25px !important;
  text-align: center;
}

.ttindicator-image-field {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
  margin-top: 20px;
}

.ttgpa-table {
  border: 1px solid $primaryHoverColor;
  padding: 5px;
  margin: 40px;
  margin-bottom: 10px;
}
.tt-reset-to-original-button {
  float: right;
}

.ttgpa-form-row {
  margin-left: 0px !important;
  padding: 5px;
}

.tt-per-table-data {
  display: flex;
  justify-content: center;
}

.tt-gpa-input {
  border: none;
  background: none;
  text-align: center;
}

.tt-per-input-field {
  width: 60px;
}

.tt-gpa-table-to {
  margin-top: 6px;
  font-weight: bold;
}

.tt-gpa-form {
  border: 2px dotted $primaryHoverColor;
  padding: 5px;
}

.tt-added-gpa-remove {
  cursor: pointer;
  color: $dangerBtnColor;
  padding: 15px;
}

.tt-span-grade-remove {
  cursor: pointer;
  color: $dangerBtnColor;
  position: relative;
  font-size: 16px;
}

//General Assessment

.tt-generalAssessmentHeader {
  background-color: $primaryColor;
  // border: 1px solid $borderColor;
  margin-bottom: 5px;
}

.tt-generalAssessmentHeaderContent {
  border: 1px solid $whiteColor;
  margin: 5px;
  padding: 2px 20px;
  font-weight: 600;
  border-radius: 25px;
}

.tt-hightlightHeaderTitle {
  color: $whiteColor;
  font-weight: 600;
  text-shadow: 1px 1px 1px $shadowColor;
  margin-top: 8px;
  margin-right: 20px;
}

.tt-generalYellow {
  background-color: $ttGeneralYellow;
}

.tt-generalRed {
  background-color: $ttGeneralRed;
  color: $whiteColor;
}

.tt-generalBlue {
  background-color: $ttGeneralBlue;
}

.tt-generalGreen {
  background-color: $ttGeneralGreen;
  color: $whiteColor;
  letter-spacing: 0.5px;
}

.tt-studentPhoto {
  margin-top: 20px;
  margin-bottom: 10px;
  border: 1px solid $borderColor;
  width: 1.2in;
  height: 1.5in;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  line-height: 8;
}

.tt-studentReportHeading {
  margin-top: 20px;
  font-weight: bold;
}

.tt-generalTable {
  th {
    padding-left: 5px;
    padding-right: 5px;
  }
  td {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.tt-generalTextarea {
  width: 100%;
  padding: 5px;
}

.tt-commentsBank {
  background-color: $primaryColor;
  border: 1px solid $borderColor;
  padding-left: 20px;
  font-weight: 500;
  padding-right: 20px;
  cursor: pointer;
}

.tt-generalBorders {
  //   border: 1px solid $primaryColor;
  //   padding-bottom: 20px;
  max-height: 76.7vh;
  overflow: auto;
}

.tt-generalAssessmentFooter {
  background-color: $primaryColor;
}

.tt-generalAssessment-student-footer {
  background-color: $primaryColor;
  margin-left: 0px !important;
  margin-right: 0px !important;
  border: 2px solid grey;
  padding: 6px;
}

.tt-generalAssessment-grade-footer {
  background-color: $dangerBtnColor;
  margin-left: 0px !important;
  margin-right: 0px !important;
  border: 2px solid grey;
  color: $whiteColor;
  padding: 6px;
}

.tt-assessment-grade-footer-content {
  border: 1px solid $whiteColor;
  margin-bottom: 8px;
  margin: 5px;
}

.tt-grade-form-row {
  margin: 5px 5px 0 5px;
}

//comment Bank
.tt-chipComment-btn {
  background-color: $primaryColor;
  border: none;
  border-radius: 5px;
  height: 25px;
  margin-top: 3px;
  font-size: 12px;
  margin-left: 5px;
  cursor: pointer;
}

.tt-commentTextArea {
  padding: 5px;
  width: 100% !important;
  border: 1px solid #ccc;
  height: 55px;
  overflow: auto;
}

.tt-commentBankDate {
  float: right;
  margin-top: 11px;
  margin-bottom: 0;
  font-size: 12px;
  font-style: italic;
}

//CommentBank CkEditor
.tt-commentBankEditor {
  .tt-textarea {
    min-height: unset !important;
    margin: 5px;
  }
  .jodit_workplace {
    min-height: unset !important;
    height: 80px !important;
  }
  .jodit_wysiwyg {
    min-height: unset !important;
  }

  .jodit_container {
    min-height: unset !important;
  }
}

.tt-associate-button {
  float: right;
}

.tt-associate-table-head {
  background-color: $primaryColor;
}

.tt-associate-list-table {
  border: 1px solid $primaryHoverColor;
}

.ttindicator-color-pick {
  // justify-content: center;
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
  padding-left: 80px !important;
}

.tt-levelTypeImagePreview {
  position: absolute;
}

.tt-grade-tableRow {
  height: 100px;
  width: 100px;
  padding: 0;
}
.tt-grade-image-div {
  border: 1px solid #ccc;
  height: inherit;
  width: inherit;
  overflow: hidden;
  box-sizing: border-box;
}

.tt-associate-wrapper {
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.list-table-row {
  margin-top: 20px;
}

.tt-printbox1 {
  height: 67vh;
  overflow-x: auto;
  overflow-y: auto;
}
.tt-printbox {
  height: 67vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.tt-stickyHeader {
  position: sticky;
  top: 1px;
  z-index: 100;
}

.jodit_symbols .jodit_symbols-container_preview,
.jodit_symbols .jodit_symbols-container_table {
  display: inline !important;
}

.jodit_dialog_box .jodit_dialog .jodit_dialog_footer {
  // display: none !important;
}

// .jodit_dialog_box .jodit_dialog.with_footer .jodit_dialog_footer .jodit_button {
//   display: none !important;
// }
