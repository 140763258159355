.calculator-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 40px repeat(5, 45px);
  grid-gap: 5px;
  padding: 10px;
  border: 1px solid black;
  width: 650px;
}

.calculator-grid input {
  grid-column: 1 / -1;
  height: 40px;
  font-size: 20px;
  text-align: right;
  padding: 5px;
}

.calculator-grid button {
  background-color: #f2f2f2;
  border: none;
  border-radius: 5px;
  color: #333;
  font-size: 24px;
  height: 45px;
}

.calculator-grid button.orange {
  background-color: #ff8c00;
  color: white;
}

.calculator-grid button.blue {
  background-color: #0099cc;
  color: white;
}

.calculator-grid button.dark-gray {
  background-color: #666;
  color: white;
}

.calculator-grid button.light-gray {
  background-color: #ccc;
}
