@import "./variables";

.tt-descriptionLMS {
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 2px 3px 3px $shadowColor;
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.tt-lms {
  table thead tr th {
    top: -8px !important;
  }
}

.tt-subTopicDiv {
  border: 1px solid #ccc;
  padding: 2px;
}

.dotsRender:before {
  position: absolute;
  right: 0;
  top: 0;
  width: 95%;
  font-weight: normal;
  overflow: hidden;
  content: " ................................................................................................................................................................................................................................................................................................................. ";
}

.backGround {
  background-color: #ffffff;
  z-index: 1;
}

.tt-phase-header {
  padding: 8px !important;
  margin-bottom: 0;
  background-color: rgba($primaryColor, 0.6) !important;
  font-weight: bold;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: $whiteColor;
  text-shadow: 1px 1px 2px $borderColor;
  -webkit-user-select: none;
  user-select: none;
}

.tt-topicHolder {
  border: 1px solid #ccc;
  padding: 13px;
  padding-top: 5px;
  box-shadow: 2px 1px 3px $shadowColor;
}

//Course Class css
.tt-courseFileSelectHolder {
  border: 1px solid #ccc;
  height: 406px;
  overflow-x: hidden;
  margin-top: 5px;
  overflow-y: auto;
  padding: 5px;
  .tt-courseFilesBox {
    border: 1px solid #ccc;
    height: 175px;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 5px;
    .tt-courseFiles {
      display: flex;
      overflow-x: auto;

      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      .tt-courseFileDelete {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        border-radius: 10px;
        background-color: $whiteColor;
        color: $primaryHoverColor;
      }

      .tt-courseFileHolder {
        margin: 5px;
        border: 1px solid $primaryHoverColor;
        margin: 5px;
        padding: 10px 30px;
        height: 140px;
        flex: 0 0 100px;
        text-align: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px;
        width: 125px;
        position: relative;

        .tt-courseFileIcon {
          font-size: 70px;
          padding: 5px;
          margin-top: 10px;
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        div {
          width: 105px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          top: 14px;
        }
       
      }
     
    }
  }
  .tt-editor{
    height: auto;
  }
  .tt-courseLinks {
    border: 1px solid #ccc;
    height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;
  }
}

.tt-courseImageHolderDetail {
  height: 87.5vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #ccc;
}

.tt-courseMainImageHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  .tt-courseImageDisplayHolder {
    width: 320px;
    height: 250px;
    border: 1px solid #ccc;
    margin: 8px;
    padding: 4px;
    cursor: pointer;
    position: relative;
    &:hover {
      box-shadow: 2px 3px 3px $shadowColor;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
}

.tt-courseLinkHolder {
  border: 1px solid #ccc;
  padding: 10px;
  height: 48.5vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.courseTermTable {
  table thead tr th {
    top: -8px;
  }
}

.lmsBorderRight {
  border-right: 1px solid $primaryColor;
}

.tt-unAssignListHolder {
  border: 1px solid #ccc;
  padding: 5px;
  height: 68.8vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 5px;
}
.tt-lectureChip {
  background: $primaryColor;
  padding: 3px 5px;
  margin-right: 2px;
  border-radius: 25px;
  color: $whiteColor;
}
.tt-practicalChip {
  background: $primaryColorDark;
  color: $whiteColor;
  padding: 3px 5px;
  border-radius: 25px;
  margin-right: 3px;
}
