@import "./variables";
.tt-subjectAssessmentControl {
  background: $primaryColor;
  margin-left: -11px;
  margin-right: -11px;
  border: 1px solid $primaryHoverColor;
  border-top: none;
  height: 26.5vh;
}

//SubjectAssessmentDisplay
.tt-subjectAssessmentDisplay {
  border: 1px solid $primaryHoverColor;
  width: 100%;
  margin-left: 4px;
  margin-right: 4px;
}

.tt-subjectAssessmentContent {
  height: 40vh;
  overflow-y: auto;
}
.tt-subjectAssessmentContent1 {
  height: 45vh;
  overflow-y: auto;
}
.tt-subject-general-assessment-config-select {
  height: auto !important;
}

.tt-AssessmentTitle {
  background: $primaryColor;
  font-size: 12px;
  font-weight: bold;
  padding: 6px;
  border-bottom: 1px solid $primaryHoverColor;
}

.tt-AssessmentSubtitle {
  text-align: center;
  font-size: 14px;
}

.tt-subjectDisplayTable {
  font-size: 13px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.tt-subjectAssessmentTextArea {
  width: 100%;
}

.tt-subjectAssessmentParagraph {
  margin: 0;
  text-align: right;
  font-size: 12px;
}

//Subject and Assessment scale Control
.tt-subjectAssessmentList {
  border: 1px solid $primaryHoverColor;
  margin-top: 10px;
}

.tt-subjectScaleRightBorder {
  border: 2px solid $primaryHoverColor;
  height: 25vh;
}

.tt-subjectScaleRightBorder1 {
  border: 2px solid $primaryHoverColor;
  overflow-y: auto;
  height: 38vh !important;
  background-color: $whiteColor;
}
.changeBorder {
  height: 26.5vh !important;
}

.tt-AssessmentButton {
  display: block !important;
}

.tt-subjectAssessmentSelect {
  width: 82%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-check-input {
  margin-left: -1.5rem;
}

label {
  margin-bottom: 0;
}

.tt-commentWord {
  text-align: center;
  margin-bottom: 5px;
  margin-top: 6px;
}

.counterBtn {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}
