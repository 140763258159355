@import "./variables";
@mixin settings($bgColor, $bdr, $width) {
  position: absolute;
  cursor: pointer;
  background-color: $bgColor;
  border: $bdr;
  width: $width;
}

.tt-profile-image {
  @include settings($whiteColor, 7px solid $whiteColor, 150px);
  margin-left: 5rem;
  position: relative;
  height: 150px;
  box-shadow: 2px 3px 3px $shadowColor;
  // top: 70px;
  border-radius: 10px;
  z-index: 1;
  margin-bottom: -100px;
  margin-top: -50px;
  p {
    text-align: center;
    line-height: 80pt;
    font-weight: bold;
  }
  img {
    object-fit: cover;
    width: 136px;
    height: 136px;
    border-radius: 5px;
  }
}

.tt-editProfileButton {
  @include settings($displayBackgroundColor, none, 100%);
  opacity: 0;
  color: $whiteColor;
  transition: 0.5s;
  text-align: center;
  bottom: 0;
  left: 0;
  font-size: 13px;
  padding: 8px 17px;
  &:hover {
    background-color: $displayBackgroundHoverColor;
  }
  &:focus {
    outline: none;
  }
}

.tt-userInfo {
  color: $whiteColor;
  text-shadow: 1px 1px 1px $shadowColor;
  font-size: 18px;
  text-align: center;
  margin-top: 7px;
  letter-spacing: 1px;
  cursor: default;
  font-weight: bold;
  background: $displayBackgroundColor;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.tt-profileFullImage {
  justify-content: center;
  height: 92%;
  max-width: 100% !important;
  .modal-content {
    width: auto;
    background-color: unset;
    border: none !important;
  }
}

.tt-fullImage {
  //   max-width: 100%;
  margin: auto;
  max-height: 95vh;
  max-width: 100%;
  //   max-width: 95.5vw;
}

.tt-profileImageHolder {
  width: 100%;
  //   height: auto;
  //   display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 7px;
  border: 1px solid #ccc;
  background-color: $whiteColor;
  box-shadow: 2px 3px 3px $shadowColor;
}

.tt-ImageCloseIcon {
  color: $whiteColor;
  position: fixed;
  right: 30px;
  top: 15px;
  cursor: pointer;
  background: $primaryColor;
  padding: 3px;
  width: 20px !important;
  font-size: 21px;
  border-radius: 15px;
  box-shadow: 1px 2px 3px $shadowColor;
  z-index: 5;
}

// .modal-backdrop.show {
//   opacity: 0.8;
// }

.tt-profileImages {
  display: flex;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .tt-profilePictureHolder {
    margin: 5px;
    border: 1px solid $primaryHoverColor;
    margin: 5px 5px 15px 5px;
    padding: 10px 30px;
    height: 98px;
    flex: 0 0 100px;
    color: $primaryColor;
    text-align: center;
    line-height: 6.5;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    width: 100px;
    position: relative;
    .tt-fileDelete {
      //   position: relative;
      //   top: -129px;
      //   left: 37px;
      //   cursor: pointer;
      position: absolute;
      top: 4px;
      /* left: 59px; */
      right: 3px;
      /* cursor: pointer; */
    }
    .tt-postFileIcon {
      font-size: 50px;
      padding: 3px;
    }
    img {
      width: 98px;
      height: 96px;
      object-fit: cover;
    }
    div {
      width: 80px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      top: 7px;
    }
  }
}

.tt-profileFileName {
  border-bottom: 1px solid $primaryHoverColor;
  margin-left: 5px;
  cursor: pointer;
  padding: 3px 0 3px 6px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  width: 100%;
  color: $primaryHoverColor;
  white-space: nowrap;
  overflow: hidden;
}

//Responsive
@media screen and (max-width: 1024px) {
  .tt-profile-image {
    width: 110px;
    margin-left: 2rem;
    height: 110px;
    // top: 68px;
    margin-bottom: -112px;
    margin-top: 0;
    img {
      width: 96px;
      height: 96px;
    }
  }
  .tt-userInfo {
    font-size: 14px;
  }
  .tt-editProfileButton {
    display: none;
  }
  .tt-profileFullImage {
    height: 90%;
  }
}

@media screen and (max-width: 480px) {
  .tt-profile-image {
    margin-left: 20px;
  }
}

@media screen and (max-width: 414px) {
  //   .tt-profile-image {
  //     width: 90px;
  //     height: 90px;
  //     img {
  //       width: 76px;
  //       height: 76px;
  //     }
  //   }
}

@media screen and (max-width: 375px) {
  .tt-profile-image {
    margin-left: 13px;
  }
}

@media screen and (max-width: 360px) {
  //   .tt-profile-image {
  //     width: 80px;
  //     border: 4px solid white;
  //     height: 80px;
  //     img {
  //       width: 72px;
  //       height: 72px;
  //     }
  //   }
  .tt-userInfo {
    font-size: 12px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 320px) {
  //   .tt-profile-image {
  //     width: 70px;
  //     height: 70px;
  //     img {
  //       width: 62px;
  //       height: 62px;
  //     }
  //   }
  .tt-userInfo {
    font-size: 11px;
  }
}
