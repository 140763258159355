@import "./variables";

.tt-form {
  //   border: 1px solid $primaryColor;

  .form-bg-color {
    background-color: $tableAlternateBgColor;
  }
}

.button-bg-color {
  background-color: $tableAlternateBgColor;
  padding-top: 10px;
  padding-bottom: 10px;
}

.table,
.table-bordered {
  // border: 1px solid $calendarBorderColor !important;
}

.table-bordered td {
  // border: 1px solid $calendarBorderColor !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: $primaryColor !important;
}

.MuiSwitch-track {
  background-color: $primaryColor !important;
}

.reportTable th {
  vertical-align: middle !important;
  padding: 5px !important;
}
