@import "./variables";

.tt-memberList {
  height: 71.5vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.tt-userList {
  height: 71.5vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.tt-groupList {
  height: 44.8vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.tt-groupCreate {
  height: 32vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.tt-selectedMember {
  height: 68.5vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.tt-selected-Students {
  height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.tt-assignRole {
  height: 9vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 0 !important;
}
