@import "./variables";

.tt-userSetting {
  .rwt__tablist:not([aria-orientation="vertical"]) {
    margin-bottom: 0 !important;
  }
  .rwt__tab {
    width: 220px !important;
  }
}
