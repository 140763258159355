@import "./variables";

.questionAnswersHolder {
  border: 1px solid #ccc;
  padding: 5px;
  max-height: 60.4vh;
  overflow-x: hidden;
  overflow-y: auto;
  .workSheetImgHolder {
    width: 100%;
    height: 150px;
    border: 1px solid #ccc;
    padding: 10px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.worksheetAnsDiv {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .answerImageDivContainer {
    margin: 8px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .worksheetTextArea {
    line-height: 1.5;
    background: repeating-linear-gradient(to bottom, transparent, transparent calc(1.5em - 1px), #ccc calc(1.5em - 1px), #ccc 1.5em);
  }
}
