@import "./variables";

.calculator-container {
  display: flex;
  justify-content: center;
  // margin-top: 50px;
}

.calculator {
  border: 2px solid #ddd;
  width: 750px;
  box-sizing: border-box;
  //   margin: 40px auto 0;
  font-family: verdana;
  background-color: white;
  .form-group {
    margin-bottom: 0;
  }
  .form-control {
    height: 70px;
  }
  input[type="text"] {
    font-size: 1.5rem;
    color: rgb(30, 30, 30);
  }
  .btn {
    font-size: 1.5rem;
    color: #333;
    width: 80px;
    background-color: $primaryHoverColor;
  }
  .btn-op {
    background-color: #c1c1c1;
  }
}

.synAnt {
  background-color: $attachFileBoxColor;
  padding: 15px;
  strong {
    color: $primaryColor;
  }
}
