@import "./variables";
.tt-block-box {
  margin-left: -110px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  cursor: pointer;
}

.tt-block-holder {
  border: 1px solid $borderColor;
  width: 250px;
  height: 250px;
  margin: 10px;
  list-style: none;
  transition: 0.5s;
  &:hover {
    background-color: $primaryHoverColor;
    box-shadow: 3px 3px 5px $primaryColor;
  }
  &:hover > .tt-block-title {
    color: $whiteColor;
  }
}

.tt-block-title {
  color: $borderColor;
  display: block;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  line-height: 8;
  transition: 0.5s;
}

.tt-tab-panel {
  transition: 0.5s ease-in;
  opacity: 0;
}

.tt-changeSize {
  font-size: 18px;
  line-height: 2;
  margin-bottom: 0;
  color: $whiteColor;
}

.tt-changeHolderSize {
  width: 150px;
  height: 40px;
  margin: 1px;
  border: none;
  background-color: #ccc;
}

.tt-backBtn-display {
  display: none;
}

.tt-backBtn {
  cursor: pointer;
  position: absolute;
  font-weight: bold;
  top: 19px;
  font-size: 17px;
  left: 25px;
}

.tt-borderBottom {
  margin-top: 0;
}

.tt-active {
  background-color: $primaryHoverColor;
}

.rwt__tablist[aria-orientation="vertical"]
  .rwt__tab[aria-selected="true"]:after {
  border-right: 3px solid $primaryHoverColor !important;
}

.rwt__tablist[aria-orientation="vertical"] {
  border-right: 1px solid $calendarBorderColor !important;
}
