@import "./variables";

.tt-create-assessment {
  margin-top: 20px;
}

.tt-create-assessment-select {
  width: 180px;
}

.tt-record-deadline-checkbox {
  position: relative;
  top: 4px;
}

.autoFormControl {
  width: 70%;
  padding: 5px;
  box-shadow: inset 2px 3px 3px rgba(0, 0, 0, 0.33);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
}

.tt-create-grade-tables-header {
  height: 40px;
  width: 100%;
  border: 1px solid $primaryColor;
  background-color: $primaryColor;
  display: flex;
  margin-top: 3px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  color: white;
}
.sub-grade-tables-header {
  margin-top: 2px !important;
  border: 1px solid $primaryColor !important;
  //background: #b7b5b5 !important;
  font-size: 1rem !important;
}
.sub-class-header {
  margin-top: 5px;
  background: #b7b5b5 !important;
  font-size: 1.1rem !important;
}
.tt-create-grade-tables {
  border: 1px solid $primaryColor;
  margin: 10px;
}

.tt-font-down-icon {
  float: right;
  margin-top: -4px;
  cursor: pointer;
}

.down-icon-sub {
  margin-top: -5px !important;
}

.tt-button-create-assessment {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto !important;
  display: block !important;
}

input[type="checkbox"]:checked {
  color: $whiteColor !important;
}

// .table-striped > thead {
//   background-color: $primaryColor;
// }

.tt-create-assessment-maintable > thead {
  background-color: $primaryColor;
  text-align: center;
}

.tt-create-assessment-maintable > tbody {
  background-color: $whiteColor;
  text-align: center;
}

.tt-class-header {
  padding: 6px;
  color: $whiteColor;
  text-shadow: 1px 1px 2px black;
  font-weight: bold !important;
}

.select__indicator {
  display: none !important;
}
.tt-subject-list-spinner {
  text-align: center;
}
