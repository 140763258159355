@import "./variables";

.primary-color {
  color: $primaryColorDark;
}

.danger-color {
  color: $dangerBtnColor;
}

.username-icon {
  font-size: 20px;
  margin-top: 7px;
}

.staffTypeCalender {
  ._2xcMq {
    width: 300px;
    height: auto;
    // box-shadow: 5px 3px 12px 0px rgb(16 36 94 / 20%);
    position: absolute;
    background-color: #fff;
    z-index: 1000 !important;
    font-size: 12px !important;
    line-height: 1.15 !important;
    color: #000;
    margin-left: 60px;
    margin-top: 0px;
    // padding: 20px;
  }
}
