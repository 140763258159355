@import "./variables";

.tt-login {
  height: 100vh;
  .tt-slider-content {
    height: 99.2vh;
  }
}

.tt-login-box {
  width: 723px;
  // border: 1px solid $borderColor;
  display: block;
  box-shadow: 2px 3px 3px $shadowColor;
  //   box-shadow: 0px 1px 5px $borderColor;
  // background-color: $whiteColor;
  border-radius: 10px;
  // border: 2px solid rgb(119, 152, 237);
  position: absolute;
  left: 50%;
  margin-left: -361px;
  top: 50%;
  margin-top: -207px;
  z-index: 3;
  .effect2:after {
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
    right: 10px;
    left: auto;
  }
}

.tt-loginBackground-slider {
  img {
    height: 100vh !important;
  }
}

.tt-login-div {
  background-color: $primaryColor;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.tt-logo {
  display: flex;
  justify-content: center;
  font-size: 35px;
  padding: 50px 25px 25px 25px;
}

.tt-login-content {
  p {
    text-align: center;
  }
}
.tt-login-input {
  // width: 100% !important;
  margin: 10px 0 10px 20px;
  background-color: $whiteColor !important;
  padding: 10px !important;
  box-shadow: inset 7px 7px 10px -5px $shadowColor;
  display: block;
  color: $primaryColor;
  p {
    text-align: center;
    margin-top: 0;
  }
  &::placeholder {
    color: $primaryColor;
    font-weight: 500;
  }
}

.tt-login-buttons {
  display: flex;
  justify-content: center;
  //padding-bottom: 69px;
}

.tt-login-btn {
  width: 100%;
  padding: 10px 20px !important;
  margin: 10px 0 10px 0 !important;
}

.tt-login-image {
  height: auto;
  // width: 719px;
  display: block;
  display: flex;
  align-items: center;
  flex-direction: column;

  background-image: url("../images/logo/loginBg1.png");
  background-position: center;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  // border-top-left-radius: 9px;
  // border-top-right-radius: 7px;
  // padding-right: 33%;
  justify-content: center;
  div {
    width: 80%;
  }
  .btnDiv {
    width: 45%;
  }
  .tt-login-logo {
    width: auto;
    height: 60px;
    padding: 4px;
    margin: 20px;
  }

  .tt-login-school-image {
    max-width: 200px;
    max-height: 300px;
    margin-top: 20px;
    padding: 10px;
    object-fit: contain;
  }
  h4 {
    margin-top: 120px;
    font-weight: 300;
    color: $primaryColor;
    text-align: center;
  }
}

.tt-checkbox-div {
  display: block;
  margin-top: 5px;
  margin-right: 88px;
  font-size: 13px;
  text-align: right;
  padding-bottom: 45px;
}

.tt-forgot-password {
  // color: $primaryColor;
  color: #202020;
  // font-weight: 600;
  font-weight: 300 !important;
  font-size: 13px;
  cursor: pointer;
  width: fit-content;
  margin-left: auto;
  // position: relative;
  // top: -7px;
  // left: 24px;
  // margin-bottom: 0;
  // cursor: pointer;
  // color: $whiteColor;
  // text-shadow: 1px 1px 2px $borderColor;
  // user-select: none;
}

.tt-checkbox {
  opacity: 1 !important;
  position: relative !important;
  top: 2px;
  margin-top: 10px;
  cursor: pointer;
  pointer-events: auto !important;
}

.tt-nameLogo {
  width: 185px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 27px;
  z-index: 1;
}

.tt-rememberMe {
  color: $whiteColor;
  cursor: pointer;
  text-shadow: 1px 1px 2px $borderColor;
  position: relative;
  left: 5px;
  top: -1px;
}

.tt-resetOption {
  // color: $whiteColor;
  cursor: pointer;
  text-shadow: 1px 1px 2px $borderColor;
  position: relative;
  left: 5px;
  top: -1px;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: $shadowColor; /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    // transform: rotate(180deg);
  }
}

//New Login Page
.loginBox {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  .innerLoginBox {
    background-color: $whiteColor;
    box-shadow: 2px 3px 3px $shadowColor;
    width: 850px;
    .leftLogoBox {
      background: linear-gradient(45deg, $primaryColor, transparent);
      // background-color: $primaryColor;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .newLogoDiv {
        margin-top: 5%;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
      }
      .newAppLinkDiv {
        p {
          margin-bottom: 0;
          color: $whiteColor;
          font-size: 15px;
          margin-bottom: 5px;
          margin-top: 20px;
          font-weight: bold;
          text-shadow: 1px 2px 3px $shadowColor;
        }
        .appLinkImages {
          display: flex;
          div {
            height: 42px;
            overflow: hidden;
            margin-left: 10px;
            margin-right: 10px;
            img {
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
    .rightFormBox {
      padding: 20px;
      .tt-userImage {
        font-size: 13rem;
        color: $primaryColor;
      }
      .form__input {
        width: 100%;
        border: 0px solid transparent;
        border-radius: 0;
        border-bottom: 1px solid $primaryBtnHoverColor;
        padding: 1em 0.5em 0.5em;
        padding-left: 2em;
        outline: none;
        margin: 1em auto;
        transition: all 0.5s ease;
        background: transparent;
        color: $primaryColor;
        &:focus {
          border-bottom: 2px solid $primaryColor;
        }
        &::placeholder {
          color: $primaryColor;
          font-weight: 500;
        }
      }
      .passwordIcon {
        position: absolute;
        right: 10px;
        top: 55%;
        font-size: 17px;
        color: $primaryColor;
      }
      .tt-checkboxNew {
        margin-top: 5px;
      }
      .tt-rememberMeNew {
        position: relative;
        top: -5px;
        left: 5px;
        color: $primaryColor;
        font-weight: 600;
        font-size: 13px;
      }
    }
  }
}

.brandLogoHolder {
  width: 100% !important;
  margin-top: 20px;
  p {
    font-size: 12px;
    margin-bottom: 0;
  }
  .brandLogo {
    width: 100px;
    // height: 80px;
    img {
      // max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}
