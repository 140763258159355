@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;700&display=swap");

.main-image1 {
  position: relative;
  height: 150px;
  // background: url("../images/loginBg2.jpg");
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.gradeClassList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  div {
    // font-family: "Comic Neue", cursive;
    // background-color: #f1f9fc;
    background-color: #cdeff9;
    // background-color: #dffefe;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 50px;
    height: auto;
    // max-width: 90px;
    // max-width: 90px;
    min-width: 10%;
    // width: 125px;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    text-align: center;
    transition: 0.2s;
    border: 2px solid white;
    // font-size: 40px;
    font-size: 1rem;

    span {
      // font-weight: 900;
      font-weight: 600;
      margin-bottom: 0;
      color: $primaryColor;
      transition: 0.2s;
      letter-spacing: -1px;
    }
    &:hover {
      background-color: lightyellow;
      border: 4px solid yellow;
      span {
        color: blue;
        font-weight: bold;
        // font-size: 1.2rem;
      }
    }
  }
  .activeGradeClass {
    // background-color: $primaryColor;
    background-color: #821879;
    color: white;
    transition: 0.2s;
    border: 2px solid yellow;
    span {
      color: white;
      // font-weight: 900;
      font-weight: 600;
      // font-size: 25px;
    }
  }
}

.subjectClassList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .subjectBoxDiv {
    // font-family: "Comic Neue", cursive;
    // background-color: #f1f9fc;
    background-color: #cdeff9;
    // background-color: #dffefe;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 150px;
    // max-width: 158px;
    // width: 150px;
    height: 230px;
    width: 230px;
    max-width: 230px;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
      0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
    cursor: pointer;
    flex-wrap: wrap;
    text-align: center;
    transition: 0.2s;
    border: 2px solid white;
    span {
      // font-weight: 900;
      font-weight: 600;
      color: $primaryColor;
      transition: 0.2s;
      font-size: 0.9rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    &:hover {
      background-color: lightyellow;
      border: 4px solid yellow;
      span {
        color: $primaryColor;
        font-weight: 700;
        font-size: 1.3rem;

        color: blue;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        line-clamp: 3;
        -webkit-box-orient: vertical;
        // border-bottom: 1px solid blue;
      }
      .gradeImgHolder {
        width: 90%;
        height: 60%;
      }
    }
  }
}

.otherActivitiesBtn {
  font-size: 14px !important;
  margin-left: auto !important;
  display: block;
  margin-right: 0 !important;
}

.gradeImgHolder {
  width: 100%;
  height: 65%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 991px) {
  .mobileSearch {
    display: none;
  }
}
