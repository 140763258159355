#navbar-toggler {
  border: none;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 5px !important;
}

#order-list {
  li {
    padding: 5px;
    a {
      color: $primaryColor;
      font-weight: 600;
      // font-size: 14.5px;
    }
    &:hover {
      background-color: $purple;
      a {
        color: white;
        font-weight: 600;
        // font-size: 14px;
      }
      .noColor {
        color: $primaryColor;
      }
    }
  }
  .liSub {
    &:hover {
      background-color: unset !important;
      background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);
      border-radius: 0.3rem;
      color: rgb(0, 0, 0);
      .noColor {
        color: white;
        background-color: unset !important;
        background: unset !important;
        border-radius: 0;
      }
    }
  }
}

#login-button {
  a {
    color: $purple;
    font-weight: 700;
  }
}

#logout-button {
  a {
    color: $purple;
    font-weight: 700;
  }
}

#signup-button {
  a {
    color: #6fcef4;
    font-weight: 700;
  }
}

.nepalFlagHolder {
  width: 50px;
  height: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.hoverTitle {
  color: #2358b2;
  font-weight: bold;
  font-size: 2.2rem;
  padding: 2rem;
  .hoverBorder {
    border-bottom: 3px solid #2358b2;
    margin-top: 50px;
  }
}

.cusHoverBtn {
  width: 100%;
  font-size: 20px !important;
}

@media screen and (max-width: 767px) {
  .nepalFlagHolder {
    display: none;
  }
}
