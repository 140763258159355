@import "./variables";

.form-input {
  width: 70%;
  padding: 5px;
  margin: 5px;
}

.addrFormat {
  width: 150px !important;
  left: -50px !important;
}

.setup-two {
  float: right;
}

.name-display-format {
  margin-top: 30px;
}
.in-report {
  margin: 3px;
  position: absolute;
  display: inline;
  text-align: center;
  left: 30%;
}
.name-tags-edit {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  background-color: $contentBackgroundColor;
  //border: 1px solid $borderColor;
}

.name-tags {
  background-color: #ccc;
  border: 1 px solid $borderColor;
  border-radius: 12px;
  top: 13px;
  margin: 2px;
  padding: 5px;
  cursor: pointer;
}
.name-tags-container {
  //display: flex;
  flex-wrap: wrap;
  padding: 5px;
  background-color: $whiteColor;
}

.input-name-display {
  position: relative;
  outline: none;
  height: 35px;
  width: 100%;
  //right: 160px;
}
.optional-field {
  margin-top: 10px;
  padding: 10px;
}

.override-chip {
  margin-top: 5px !important;
  margin-right: 5px !important;
}

.overdue-btn{
  color: red;
  background-color: white;
  border-radius: 50%;
  padding: 10px;
}

// .identity-cluster {
//   background-color: $contentBackgroundColor;
//   padding: 10px 0 5px 2px;
//   //font-size: 1.3rem;
//   margin-bottom: 10px;
// }

.submit-setup {
  margin-top: 15px;
  //padding: 10px;
}
.literal-input {
  margin-top: 15px;
  outline: none;
  float: right;
  border: 1px solid $primaryColor;
  border-radius: 3px;
  width: 259px;
  padding: 5px;
  &:focus {
    outline: none;
  }
}
.mandatory-checkbox {
  height: 15px;
  width: 15px;
  position: relative;
  top: 6px;
}
.fa-times-icon {
  height: 15px !important;
  width: 15px !important;
}

.tt-systemSetupContent {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.tt-systemSetupTwo {
  margin: -18px -18px 18px -18px !important;
}

.tt-bold {
  font-weight: bold;
}

.address-format {
  margin-left: 16px;
}
