@import "./variables";
.panel-body {
  padding: 0px;
}

.list-group {
  margin-bottom: 0;
}

.panel-title {
  display: inline;
}

.label-info {
  float: right;
}

li.list-group-item {
  border-radius: 0;
  border: 0;
  border-top: 1px solid #ccc;
}

li.list-group-item:hover {
  background-color: $commentListColor;
}

.mic-info {
  color: grey;
  font-size: 11px;
}

.action {
  margin-top: 5px;
  float: right;
}

.comment-text {
  font-size: 12px;
}

.btn-block {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.image-dimension {
  //  width: 80px;
  //  height: 80px;
  border-radius: 50px;
  border: 1px solid $primaryColor;
}

.tt-noticeRSVP {
  .commentList {
    .list-group-item {
      background-color: unset !important;
    }
    .panel-body {
      &:nth-child(even) {
        background-color: $tableAlternateBgColor;
      }
    }
  }
}

.img-NoticecommentDisplay {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
