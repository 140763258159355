@import "./variables";

@mixin alignBtnTextCenter($fontSize, $bdrColor) {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding: 12px 18px;
  font-size: $fontSize;
  border: 1px solid $bdrColor;
}

.horizontaltwo[aria-selected="true"] {
  background-color: $primaryHoverColor !important;
  color: #fff !important;
  font-weight: 500;
  border-radius: 50%;

  .topic {
    font-weight: bold;
  }
}

.horizontaltwo {
  height: 46px;
  width: 46px;
  background-color: $primaryColor;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  color: $borderColor;
  padding-top: 10px;
  cursor: pointer;
}

.middle123 {
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 30px;
}

.middle123 li:focus {
  outline: none !important;
}

.dot:focus {
  outline: none !important;
}

.horizontalline {
  width: 150px;
  height: 2px;
  background-color: $primaryColor;
  margin-top: 20px;
}

.topic {
  font-size: 11px;
  color: $borderColor;
  margin-top: 7px;
  text-align: center;
  font-family: Arial;
}

// .react-tabs {
//   padding: 10px;
// }

.create-academic {
  text-align: center;
  position: relative;
  top: 11px;
  left: -39px;
  width: 130px;
}

.term-setup {
  position: relative;
  top: 11px;
  left: -55px;
  width: 158px;
}

.weekend-setup {
  position: relative;
  top: 11px;
  left: -25px;
  width: 101px;
}

.quote {
  color: $borderColor;
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  color: $whiteColor;
  margin-bottom: 0;
}

.main-panel {
  border: none !important;
  padding: 0 !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none !important;
  opacity: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

input[type="date"] {
  position: relative;
  opacity: 1;
}

input[type="date"]::-webkit-clear-button {
  display: none;
}

.academic-content {
  height: 43vh;
  border: 1px solid $primaryColor;
  padding: 25px;
  margin-top: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

#increase {
  height: 8px;
  width: 30px;
  padding: 7px;
  font-size: 18px !important;
  position: relative;
  margin-left: 46px;
  outline: none !important;
}

#increaseSubjectAssessment {
  height: 8px;
  width: 24px;
  padding: 7px 0;
  font-size: 18px !important;
  outline: none !important;
}


#increase:focus {
  box-shadow: none;
}

#decrease {
  position: absolute;
  height: 8px;
  width: 30px;
  padding: 7px;
  font-size: 18px !important;
  z-index: 1;
  //margin-right: 5px !important;
}


#decreaseSubjectAssessment {

  height: 8px;
  width: 30px;
  padding: 7px;
  font-size: 18px !important;
  z-index: 1;
  //margin-right: 5px !important;
}

input#number {
  text-align: center;
  border: none;
  border-top: 1px solid #bce0fd;
  border-bottom: 1px solid #bce0fd;
  margin-top: 6px;
  width: 100px;
  height: 29px;
}

input#number:focus {
  text-decoration: none !important;
}

.icon-minus {
  font-size: 24px;
  color: $primaryColor;
  cursor: pointer;

  &:hover {
    color: $primaryHoverColor;
  }
}

#nextbutton {
  height: 40px;
  width: 176px;
  color: $whiteColor;
  margin-left: unset !important;
}

.nextbutton {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding: 10px;
}

#previous-button {
  height: 40px;
  width: 176px;
  margin-right: 35px;
  margin-left: unset !important;
}

.ttedu-level-dropdown {
  // border-radius: 15px;
  width: 100%;
  height: 29px;
  background-color: $whiteColor;
  font-family: Arial;
  font-size: 12px;
  color: $borderColor;
  margin-bottom: 3%;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: $primaryHoverColor;
  }
}

option:hover {
  background-color: $primaryHoverColor !important;
}

/*CSS for SuperScripting '*' for mandatory fields in a form*/
.optionalSup {
  top: -2.2em;
  right: -0.5em;
  float: right;
  color: $dangerBtnColor;
  font-size: 12px;
}

.ttacademic-form {
  margin-top: 15px;
  padding: 5px;
}

.tt-academicYearLabel {
  font-weight: bold;
  text-align: right;
  padding-top: 10px;
}

.tt-weekdaysDisplay {
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.add-salutation {
  margin-bottom: -7px !important;
}

.date-picker {
  top: 2px !important;
  width: 32px !important;
  //height: 20px !important;
  float: right;
  opacity: 0 !important;
}

.date-fa {
  //position: absolute;
  z-index: 9999999999999;
  font-size: 1.2rem;
  float: right !important;
  margin-top: 8px !important;
  margin-right: -32px !important;
}

.ttprimary-header {
  background-color: $primaryColor;
  margin-left: 0;
  margin-right: 0;
}

.ttprimary-title {
  margin-bottom: 7px;
  margin-top: 5px;
  font-weight: bold;
}

.conHeight {
  height: 79.5vh !important;
}

.acaContent {
  height: 59vh !important;
}

.tt-nepali-calendar-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.tt-nepali-event-add-form {
  border: 1px solid $primaryHoverColor;
  text-align: center;
}

.tt-bubble-background {
  display: block;
  text-align: left;
  line-height: 1;
  font-size: 14px;

  div {
    display: inline;
    background-color: $primaryColor;
    padding: 2px 20px;
    line-height: 2.5;
    color: $whiteColor;
    margin-right: 10px;
    border-radius: 10px;
    cursor: pointer;
  }
}

.tt-background {
  &:nth-child(odd) {
    background-color: #c1d8f3;
  }

  height: auto;
  overflow: auto;
}

.tt-semester-background {
  box-shadow: 0 0 0 1px rgba($color: blue, $alpha: 0.3);
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
}

#academicYears {
  height: 79.5vh;
  overflow-x: hidden;
  overflow-y: auto;
}

#collAcademicYear {
  height: 53.8vh;
  overflow: auto;
}

.tt-weekday-wrapper {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
