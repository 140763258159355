@import "./variables";

.tt-previewBox {
  background-color: $primaryColor;
  padding: 10px;
  display: flex;
  justify-content: center;
  height: 80vh;
  padding: 3%;
}

.tt-reportPaper {
  background-color: $whiteColor;
  box-shadow: 2px 3px 5px $borderColor;
  zoom: 40%;
}

.optGroup-option {
  white-space: pre-line;
  padding-bottom: 2px;
  border-bottom: 1px solid #ccc;
}

.tt-reportMagnifier {
  position: absolute;
  right: 10px;
  top: 10px;
  div {
    background: $whiteColor;
    color: $primaryColor;
    font-size: 16px;
    padding: 5px 9px;
    border-radius: 25px;
    margin-bottom: 10px;
    box-shadow: 2px 3px 3px $shadowColor;
  }
}

.descriptionBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ccc;
  font-weight: bold;
  font-size: 30px;
}
