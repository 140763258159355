@import "./variables";
@import "./headNav";
@import "./content";
@import "./gradePage";
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap");

body {
  // font-family: "Comic Neue", cursive !important;
  font-family: "Poppins", sans-serif !important;
}

a {
  text-decoration: none !important;
}

.sr-only {
  display: none;
}

.actionIcon {
  font-size: 17px;
  padding: 5px;
  // position: relative;
  // top: 3px;
  font-weight: bold;
  cursor: pointer;
}

.assignBox {
  background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);
  color: white;
  padding: 5px 10px;
  border-radius: 25px;
  cursor: pointer;

  &:hover {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
      0rem 0.4375rem 0.625rem -0.3125rem rgba(64, 64, 64, 0.4);
  }
}

body {
  color: $bodyTextColor !important;
  background-color: $bodyBgColor !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.text-primary {
  color: $selectedPrimaryColor;
}

.contentBtn {
  margin: 0;
  font-size: 0.775rem;
  line-height: 1.625;
  color: $headerTileColor;
  font-weight: 400;
  letter-spacing: 0.0075em;
  opacity: 1;
  text-transform: capitalize;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;

  &:hover {
    font-weight: 700;
    transition: 0.3s;
  }
}

.contentSubtitle {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: $cardTextColor;
  font-weight: 300;
}

.contentTitle {
  margin: 0;
  font-size: 1rem;
  line-height: 1.625;
  color: $headerTileColor;
  font-weight: 700;
  letter-spacing: 0.0075em;
  opacity: 1;
  text-transform: capitalize;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.cusCardHeader {
  display: flex;

  .cusCardLabelBox {
    flex: 1;

    .cusCardLabel {
      margin: 0;
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.5;
      text-transform: uppercase;
      letter-spacing: 0.02857em;
      opacity: 1;
      text-transform: none;
      vertical-align: unset;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: $cardTextColor;
      font-weight: 300;
      text-align: right;
    }

    .cusCardValue {
      margin: 0;
      font-size: 1.5rem;
      line-height: 1.375;
      font-weight: 700;
      letter-spacing: 0.00735em;
      opacity: 1;
      text-transform: none;
      vertical-align: unset;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: $headerTileColor;
      text-align: right;
    }
  }

  .cusCardIcon {
    // flex: 1;
    position: relative;

    .cardIconBox {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      margin-top: -30px;
      opacity: 1;
      // background: linear-gradient(195deg, #42424a, #191919);
      color: #ffffff;
      border-radius: 0.75rem;
      box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
        0rem 0.4375rem 0.625rem -0.3125rem rgba(64, 64, 64, 0.4);
    }

    .cardIconIndex0 {
      background: linear-gradient(195deg, #42424a, #191919);
    }

    .cardIconIndex1 {
      background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);
    }

    .cardIconIndex2 {
      background: linear-gradient(195deg, #66bb6a, #43a047);
    }

    .cardIconIndex3 {
      background: linear-gradient(195deg, #ec407a, #d81b60);
    }

    .cardIconIndex4 {
      background: linear-gradient(195deg, #f1b41b, #e5a912);
    }
  }
}

.cardSeparator {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  background-color: transparent;
  background-image: linear-gradient(to right,
      rgba(52, 71, 103, 0),
      rgba(52, 71, 103, 0.4),
      rgba(52, 71, 103, 0)) !important;
  height: 0.0625rem;
  margin: 1rem 0;
  border-bottom: none;
  opacity: 0.25;
}

.cusCardContent {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.02857em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: $cardTextColor;

  span {
    color: #4caf50;
    font-weight: 700;
  }
}

.cusBtn {
  // inline-size: 140px;
  //   overflow-wrap: break-word;
  // background-color: $btnColor;
  margin-bottom: 10px !important;
  background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);
  border-radius: 80px !important;
  padding: 4px 15px !important;
  color: white;
  font-weight: 100;
  // font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  border: none;
  position: relative;

  &:hover {
    background: $primaryBtnHoverColor;
  }

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: 50%;
    border-radius: 5px;
    background: linear-gradient(hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 100%, 0.2));
  }
}

.cusBtnDanger {
  background: linear-gradient(195deg, #ef5350, #f44336);
  border-radius: 80px !important;
  padding: 4px 15px !important;
  color: white;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: black;
  }

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: 50%;
    border-radius: 5px;
    background: linear-gradient(hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 100%, 0.2));
  }
}

.cusBtnFontSize {
  font-size: 0.795rem;
  margin: 0px 5px;
}

.cusChartHeader {
  position: relative;
  display: flex;
  flex-direction: column;

  .cusChartHolder {
    padding: 12px;
    margin-top: -40px;
    height: 12em;
    opacity: 1;
    // background: linear-gradient(195deg, #49a3f1, #1a73e8);
    background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);

    color: $headerTileColor;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
      0rem 0.4375rem 0.625rem -0.3125rem rgba(0, 187, 212, 0.4);
  }

  .viewSummaryHolder {
    background: linear-gradient(195deg, #66bb6a, #43a047);
  }
}

.chartMaterialIcon {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 0.875rem !important;
  color: $cardTextColor !important;
  position: relative;
  top: 3.5px;
}

.cusChartPadding {
  padding-left: 8px;
  padding-right: 8px;
}

.cusInput {
  font-size: 0.875rem !important;
  border-radius: 80px !important;
  color: $cardTextColor !important;

  &::placeholder {
    color: $cardTextColor !important;
  }
}

.cusDesc {
  font-size: 0.875rem !important;
  border-radius: 20px !important;
  color: $cardTextColor !important;

  &::placeholder {
    color: $cardTextColor !important;
  }
}

.cusInputSelect {
  font-size: 0.875rem !important;
  border-radius: 80px !important;
  color: $cardTextColor !important;
}

.cusTable {
  // border-spacing: 0;
  font-size: 0.75rem;
  margin-bottom: 0 !important;

  thead {
    opacity: 1;
    background: transparent;
    color: $headerTileColor;
    border-radius: none;
    box-shadow: none;
  }

  tr {
    color: inherit;
    vertical-align: middle;
    outline: 0;

    th {
      opacity: 0.7;
      background: transparent;
      border-top: none;
      color: $cardTextColor;
      font-weight: 700;
      font-size: 0.65rem;
      border-radius: none;
      box-shadow: none;
      border-bottom: 0.0625rem solid #f0f2f5;
      text-transform: uppercase;
      position: relative;
      padding: 8px !important;
    }

    td {
      opacity: 1;
      background: transparent;
      color: $headerTileColor;
      font-weight: 300;
      border-radius: none;
      box-shadow: none;
      border-bottom: 0.0625rem solid #f0f2f5;
      word-break: break-all;
      padding: 8px !important;
    }
  }
}

.customTextInput {
  background-color: $inputBg;
  width: 100%;
  padding: 18px 18px 18px 45px;
  border-radius: 80px;
  outline: none;
  border: none;
  color: $inputTextColor;
  box-shadow: unset !important;

  &::placeholder {
    color: $iconColor;
    font-weight: 300;
  }
}

.registerErr{
  text-align: left;
  margin: 0 10px;
  font-size: 13px !important;
}


.loginReactIcon {
  position: absolute;
  left: 10px;
  top: 5px;
  color: $iconColor;
  z-index: 1000;
}

.loginReactSelect__control {
  background-color: $inputBg !important;
  width: 100%;
  padding: 13px 18px 13px 45px;
  border-radius: 80px !important;
  outline: none;
  border: none !important;
  box-shadow: unset !important;
  color: $inputTextColor;

  .loginReactSelect__indicators{
    position: relative;
    bottom: 40%;
  }

  .loginReactSelect__value-container {
    padding: 0 !important;
    color: $inputTextColor;
    bottom: 40%;

   
    .loginReactSelect__input {
      color: $inputTextColor;
    }

    .loginReactSelect__placeholder {
      color: $iconColor;
      font-weight: 300;
    }
  }
}

.dashboardPieChartHolder {
  // border-radius: 0.75rem;
  // box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
  //   0rem 0.4375rem 0.625rem -0.3125rem rgba(64, 64, 64, 0.4);
  padding: 15px;
}

.dashboardSchoolLogoHolder {
  border-left: 1px solid #ccc;
}

.dashboarLogoDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  .dashboardSchoolLogo {
    width: 100px;
    height: 100px;
    // border-radius: 50%;
    object-fit: contain;
    margin: 1rem;
  }
}

.deleteColor {
  color: $deleteColor;
}

.drpBtn {
  background: unset;
  border: unset;
  color: $cardTextColor;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    background: unset;
    border: unset;
    color: $headerTileColor;
  }
}

.dropdownIcon {
  position: relative;
  top: 3px;
  cursor: pointer;
}

.dropdown-item {
  color: rgb(0, 0, 0);
}

.dropdown-menu {
  min-width: 16rem !important;
}

.dropdown-menu.show {
  border: none;
  box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
    0rem 0.4375rem 0.625rem -0.3125rem rgba(64, 64, 64, 0.4);
  font-weight: 300;
  font-size: 0.875rem;
  // margin-top: 5px;
  border-radius: 0.4rem;
  padding: 1rem;

  .dropdown-item {
    color: rgb(0, 0, 0);

    &:hover {
      background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);
      border-radius: 0.3rem;
      color: rgb(0, 0, 0);
    }
  }
}

.editColor {
  color: $editColor;
}

.AddColor {
  color: $primaryColor;
}

.eventTypeBox {
  padding: 3px 10px;
  color: white;
  border-radius: 0.4rem;
  width: fit-content;
  font-weight: 500;
}

.eventTypeColorBox {
  width: 50px;
  height: 30px;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
}

.fc .fc-button-primary {
  background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);
  border-color: unset;
  outline: unset;

  &:hover {
    border-color: unset;
    outline: unset;
  }

  &:focus {
    box-shadow: unset;
  }
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: $calendarToday;
}

.fc .fc-daygrid-day-number {
  font-size: 40px;
  opacity: 0.6;
  color: $cardTextColor;
}

.fc-col-header {
  background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);
  border: none;

  thead {
    tr {
      th {
        a {
          color: white;
        }
      }
    }
  }
}

.formTitle {
  background: transparent;
  color: $cardTextColor;
  font-weight: 700;
  font-size: 0.75rem;
  border-radius: none;
  box-shadow: none;
  position: relative;
  top: 8px;
  letter-spacing: 0.035rem;

  .optClass {
    font-size: 10px;
    font-weight: 300;
  }
}

.fullWindow-Spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  color: white;

  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}

header {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: -webkit-relative;
  position: relative;
  top: 0;
  left: auto;
  right: 0;
  color: inherit;
  box-shadow: none;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  background-color: transparent !important;
  color: #7b809a;
  top: 0.75rem;
  min-height: 4.6875rem;
  display: grid;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0px;
  padding-left: 0px;

  .breadCrumbHolder {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.625;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.6);

    ol {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0;
      margin: 0;
      list-style: none;

      .breadCrumbSeparator {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-left: 8px;
        margin-right: 8px;
        font-size: 0.875rem;
        color: #6c757d;
      }

      li {
        line-height: 0;

        span {
          margin: 0;
          font-size: 1rem;
          font-weight: 300;
          line-height: 1.6;
          letter-spacing: 0.01071em;
          opacity: 0.5;
          text-transform: none;
          vertical-align: unset;
          -webkit-text-decoration: none;
          text-decoration: none;
          color: $headerTileColor;
          line-height: 0;
        }

        .breadCrumbText {
          margin: 0;
          font-size: 0.875rem;
          font-weight: 300;
          line-height: 1.5;
          text-transform: uppercase;
          letter-spacing: 0.02857em;
          opacity: 1;
          text-transform: capitalize;
          vertical-align: unset;
          -webkit-text-decoration: none;
          text-decoration: none;
          color: $headerTileColor;
          font-weight: 400;
          line-height: 0;
        }
      }
    }
  }

  .headerPageTitle {
    margin: 0;
    margin-top: 10px;
    font-size: 1rem;
    line-height: 1.625;
    font-weight: 700;
    letter-spacing: 0.0075em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 1;
    text-transform: capitalize;
    vertical-align: unset;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: $headerTileColor;
  }
}

.imageHolder {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.listBox {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: -24px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
  opacity: 1;
  background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);
  color: $headerTileColor;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
    0rem 0.4375rem 0.625rem -0.3125rem rgba(0, 187, 212, 0.4);
  display: flex;
  color: #ffffff;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;

  .listTitle {
    margin: 0;
    font-size: 1rem;
    line-height: 1.625;
    font-weight: 600;
    letter-spacing: 0.0075em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    -webkit-text-decoration: none;
    text-decoration: none;
  }

  .listIcon {
    cursor: pointer;

    &:hover {
      text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.33);
      transition: 0.3s;
      font-size: 25px;
    }
  }
}

.loginBg {
  position: relative;
  z-index: 5;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  // background-image: url("../images/bannerLogin.svg");
  // background-image: linear-gradient(0deg,
  //     rgba(72, 120, 174, 0.6),
  //     rgba(72, 120, 174, 0.6)),
  //   url("../images/loginBg.png");
}

.loginBtn {
  width: 220px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8%;
}

.signInChanger{
  color: #6ea6b5;
  font-weight: 400;
  font-size: 14px;
  padding-right: inherit;
  cursor: pointer;
}

.loginDiv {
  height: 100vh;
  overflow: hidden;

  .newLoginBgdiv{
    background-image:url("../images/newLoginBg2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .loginBoxDiv {
    display: flex;
    justify-content: center;
    background-color: transparent;
    // background-color: white;
    align-items: center;
    height: 100vh;
    overflow: auto;

    .loginBoxHolder {
      width: 40%;
      // width: 70%;
      position: relative;
      height: 100%;
      background-color: white;
      // overflow: hidden;
      // border-radius: 1.375rem;
      // box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
      padding: 25px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      bottom: 7%;

      .login-toggler {
        // margin-top: 5% !important;
        // background: #191828;
        // color: #efedef;
        font-family: "Roboto", Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.01em;
        line-height: 1.6em;
        margin: 0;
        width: 100%;
        padding: 35px 100px;

        h1 {
          font-size: 40px;
          line-height: 0.8em;
          // color: rgba(255, 255, 255, 0.2);
        }

        .made-with {
          background: #122407;
          color: #fff;
          display: block;
          font-size: 12px;
          line-height: 1em;
          margin: 0;
          padding: 5px 110px;
          position: fixed;
          top: 20px;
          right: -100px;
          text-align: center;
          text-decoration: none;
          transform: rotate(45deg);
        }

        .documentation {
          color: $primaryColor;
        }

        button:focus,
        input:focus,
        textarea:focus,
        select:focus {
          outline: none;
        }

        .tabs {
          display: block;
          display: -webkit-flex;
          display: -moz-flex;
          display: flex;
          -webkit-flex-wrap: wrap;
          -moz-flex-wrap: wrap;
          flex-wrap: wrap;
          margin: 0;
          overflow: hidden;
        }

        .tabs [class^="tab"] label,
        .tabs [class*=" tab"] label {
          // color: #efedef;
          cursor: pointer;
          display: block;
          font-size: 1.1em;
          font-weight: 300;
          line-height: 1em;
          padding: 1rem 0;
          text-align: center;
          background: #e9ecf1;
        }

        .indTab {
          border-top-left-radius: 80px;
          border-bottom-left-radius: 80px;

          &:hover {
            background-color: #77adcc !important;
            color: white;
            font-weight: bold;
          }
        }

        .insTab {
          border-top-right-radius: 80px;
          border-bottom-right-radius: 80px;

          &:hover {
            background-color: #77adcc !important;
            color: white;
            font-weight: bold;
          }
        }

        .actTab {
          background: linear-gradient(195deg, #49a3f1, #126696) !important;
          color: white;
          font-weight: bold;
        }

        .tabs [class^="tab"] [type="radio"],
        .tabs [class*=" tab"] [type="radio"] {
          // border-bottom: 1px solid rgba(239, 237, 239, 0.5);
          cursor: pointer;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          display: block;
          width: 100%;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }

        .tabs [class^="tab"] [type="radio"]:hover,
        .tabs [class^="tab"] [type="radio"]:focus,
        .tabs [class*=" tab"] [type="radio"]:hover,
        .tabs [class*=" tab"] [type="radio"]:focus {
          border-bottom: 1px solid $primaryColor;
        }

        .tabs [class^="tab"] [type="radio"]:checked,
        .tabs [class*=" tab"] [type="radio"]:checked {
          border-bottom: 2px solid $primaryColor;
        }

        .tabs [class^="tab"] [type="radio"]:checked+div,
        .tabs [class*=" tab"] [type="radio"]:checked+div {
          opacity: 1;
        }

        .tabs [class^="tab"] [type="radio"]+div,
        .tabs [class*=" tab"] [type="radio"]+div {
          display: block;
          opacity: 0;
          padding: 2rem 0;
          width: 90%;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }

        .tabs .tab-2 {
          width: 50%;
        }

        .tabs .tab-2 [type="radio"]+div {
          width: 200%;
          margin-left: 200%;
        }

        .tabs .tab-2 [type="radio"]:checked+div {
          margin-left: 0;
        }

        .tabs .tab-2:last-child [type="radio"]+div {
          margin-left: 100%;
        }

        .tabs .tab-2:last-child [type="radio"]:checked+div {
          margin-left: -100%;
        }
      }

      .loginTitle {
        font-weight: 700;
        line-height: 44px;
        margin-top: 2%;
        margin-bottom: 2%;
        color: $primaryColor;
      }

      .loginIcon {
        position: absolute;
        left: 10px;
        top: 12px;
        color: $iconColor;
      }

      .loginIconPsw {
        position: absolute;
        left: 10px;
        top: 20px;
        color: $iconColor;
      }

      img {
        width: 45%;
        // width: 25%;
        height: auto;
        object-fit: contain;
        text-align: center;
      }

      .forgotPsw {
        font-size: 15px;
        // line-height: 18px;
        // letter-spacing: -0.005em;
        // color: $inputTextColor;
        font-weight: 300;

        // cursor: pointer;
        // &:hover {
        //   font-weight: 500;
        //   color: $bodyTextColor;
        // }
      }

      .forgotPsw-hover {
        font-size: 15px;
        // line-height: 18px;
        // letter-spacing: -0.005em;
        // color: $inputTextColor;
        font-weight: bold;

        cursor: pointer;

        a {
          color: $primaryColor;
          font-weight: 600;
          font-size: 18px;
        }

        &:hover {
          font-weight: 600;
          color: $bodyTextColor;
        }
      }

      .bottomText {
        font-size: 16px;
        font-weight: 400;
        // font-weight: bold;
        letter-spacing: 0.145em;
        line-height: 19px;
        // color: rgba(37, 39, 47, 0.74);
        color: #6bb2ef;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.forgotPasswordEye{
  position: absolute;
    top: 37%;
    right: 5%;
    color: #00000057;
    cursor: pointer;
}

.multipleFieldBtnBox {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    cursor: pointer;
  }
}

.myProfileData {
  color: $cardTextColor;
  font-weight: 300;
  // text-transform: uppercase;
  font-size: 0.85rem;
}

.myProfileImgDiv {
  .change-profile-img {
    background-color: #007bb6;
    width: 100%;
    height: 40px;
    padding: 10px;
    text-align: center;
    color: white;
    font-weight: 600;

    &:hover {
      background-color: #00aced;
      cursor: pointer;
    }
  }

  border-left: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .myProfileImg {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    margin: 2rem;
  }
}

.resourceBtn {
  width: fit-content;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: 2.7rem;
}

.roleAndPermissionBox {
  border: 1px solid #ccc;
  padding: 10px;
  max-height: 450px;
  overflow: auto;
}

.mobileSidebar {
  display: none;
}

.sidebarCss {
  box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
  margin-bottom: inherit;
  left: 0;
  width: 250px;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    background-color 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    background-color 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  border: none !important;
  // background: linear-gradient(
  //   195deg,
  //   $sidebarGradient1,
  //   $sidebarGradient2
  // ) !important;
  background: linear-gradient(195deg, #00236690, #002366) !important;
  color: $bodyTextColor !important;
  overflow-y: auto;
  flex-direction: column;
  // z-index: 1200;
  outline: 0;
  height: calc(100vh - 2rem);
  margin: 1rem;
  border-radius: 0.75rem;
  color: white !important;

  .sidebarSubmenu {
    .ps-submenu-content {
      overflow: visible !important;
      height: 100% !important;
      background-color: unset;
    }
  }

  .ps-sidebar-container {
    background-color: unset !important;

    .sidebarCollapsedHeader {
      padding-left: unset !important;
      padding-right: unset !important;
    }

    .sidebarHeader {
      padding-top: 24px;
      padding-bottom: 8px;
      padding-left: 32px;
      padding-right: 32px;
      text-align: center;
      opacity: 1;
      background: transparent;
      color: #344767;
      border-radius: none;
      box-shadow: none;

      img {
        width: 35px;
        height: 35px;
        object-fit: contain;
      }

      .sidebarHeaderText {
        margin: 0;
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 1.5;
        text-transform: uppercase;
        letter-spacing: 0.02857em;
        opacity: 1;
        text-transform: none;
        vertical-align: unset;
        -webkit-text-decoration: none;
        text-decoration: none;
        color: #ffffff;
        font-weight: 600;
      }
    }

    .ps-menu-root {
      height: calc(100vh - 205px);
      overflow: auto;
    }

    .sidebarSeparator {
      margin: 0;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      border-width: 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.08);
      height: 0.0625rem;
      margin: 1rem 0;
      border-bottom: none;
      opacity: 0.25;
      background-color: transparent;
      background-image: linear-gradient(to right,
          rgba(255, 255, 255, 0),
          #ffffff,
          rgba(255, 255, 255, 0)) !important;
    }

    .css-ewdv3l {
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-top: 0;
      padding-bottom: 0;

      li {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 10px;

        a {
          font-weight: 300;
          font-size: 0.875rem;
          color: white;
          padding-left: 0.5rem;
          padding-right: 0.5rem;

          &:hover {
            background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);
            border-radius: 0.375rem;
          }
        }
      }

      .ps-active {
        a {
          background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);
          border-radius: 0.375rem;
        }
      }
    }

    .sidebarIcon {
      font-size: 1.25rem;
    }
  }
}

.modal-title {
  margin: 0;
  font-size: 1rem;
  line-height: 1.625;
  // color: $whiteColor;
  font-weight: 700;
  letter-spacing: 0.0075em;
  opacity: 1;
  text-transform: capitalize;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.modal-header .btn-close {
  font-size: 12px;
  color: $headerTileColor;
  font-weight: bold;
}

.primaryColor {
  color: $primaryColor;
}

.sysCusBtn {
  font-size: 0.75rem;
  width: fit-content;
  padding: 8px 20px;
  margin-bottom: 1rem;
}

.userHeaderBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.userHeaderImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.userHeaderSeparator {
  font-size: 8px;
  font-weight: 300;
}

.userHeaderTitle {
  // font-weight: 300;
  font-size: 15px;
  color: $headerTileColor;

  span {
    font-size: 12px;
    font-weight: 300;
  }
}

.userImage {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.whiteContainer {
  background-color: #fff;
  // background: #F1F9FC 0% 0% no-repeat padding-box;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
    0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  overflow: visible;
  width: 100%;
  padding: 15px;
  margin-bottom: 50px;
  min-height: 105px;
}

.flat-border {
  // border: 15px solid $purple;
  // background-color: $purple;
  background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);
  // height: 40px;
  padding: 20px 0;
  margin: 0px;

  ol {
    margin-bottom: 0;

    li {
      a {
        font-weight: bold;
      }
    }

    .breadcrumb-item+.breadcrumb-item::before {
      color: white !important;
    }
  }

  span {
    color: white;
  }
}

// .fullModal {
//   min-width: 90vw !important;
//   // height: 80vh !important;
//   // max-width: none;

//   .modal-body {
//     //   max-width: 88vw !important;
//     .file-preview-col {
//       max-height: 78vh !important;
//       min-height: 70vh !important;
//       width: 100%;
//       overflow-y: scroll;
//     }
//     .file-list-col {
//       // max-height: 78vh !important;
//       // min-height: 70vh !important;
//       overflow: scroll;
//       overflow-x: hidden;
//       position: absolute;
//       right: 0px;
//       top: 50px;
//       background-color: #007cb65e;
//       z-index: 100;
//       height: 100%;
//     }

//     .download-col {
//       background: $primaryColor;
//       margin-bottom: -42px;
//       position: relative;
//       z-index: 2;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       text-align: center;
//       font-weight: bold;
//       // text-transform: uppercase;
//       text-shadow: 1px 2px 2px $shadowColor;
//       h6 {
//         color: #fff;
//         font-size: 20px;
//         margin-bottom: 0;
//         // padding: 10px;
//       }
//     }
//   }
//   .activeIndex {
//     color: #7798ed;
//   }
// }

.sr-only {
  display: none;
}

.whiteContainer-01 {
  background-color: #fff;
  // background: #F1F9FC 0% 0% no-repeat padding-box;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
    0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  overflow: visible;
  width: 100%;
  padding: 15px;
}

.tt-courseFileSelectHolder {
  border: 1px solid #ccc;
  height: 406px;
  overflow-x: hidden;
  margin-top: 5px;
  overflow-y: auto;
  padding: 5px;

  .tt-courseFilesBox {
    border: 1px solid #ccc;
    height: 175px;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 5px;

    .tt-courseFiles {
      display: flex;
      overflow-x: auto;

      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      .tt-courseFileDelete {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        border-radius: 10px;
        background-color: $whiteColor;
        color: $primaryHoverColor;
      }

      .tt-courseFileHolder {
        margin: 5px;
        border: 1px solid $primaryHoverColor;
        margin: 5px;
        padding: 10px 30px;
        height: 140px;
        flex: 0 0 100px;
        text-align: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px;
        width: 125px;
        position: relative;

        .tt-courseFileIcon {
          font-size: 70px;
          padding: 5px;
          margin-top: 10px;
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        div {
          width: 105px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          top: 14px;
        }
      }
    }
  }

  .tt-editor {
    height: auto;
  }

  .tt-courseLinks {
    border: 1px solid #ccc;
    height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;
  }
}

.pdf-viewer {
  text-align: center;
}

// .tt-button{
//   width: 100px;
//   height: 20px;
// }

@mixin btnColors($color, $bgColor, $bgImage) {
  color: $color !important;
  background-color: $bgColor !important;
  background-image: linear-gradient($bgColor, $bgImage) !important;
}

.tt-button {
  display: inline-block;
  position: relative;
  margin-left: auto;
  padding: 4px 20px;
  font-size: 13px;
  outline: none !important;
  border: 1px solid $whiteColor;
  border-radius: 5px;
  font-weight: 600;
  margin: 0 5px;
}

.tt-button-primary {
  @include btnColors($whiteColor, $primaryBtnColor, $primaryGradientColor);

  &:hover,
  &:focus {
    background: $primaryBtnHoverColor;
  }
}

.toolTip {
  display: flex;
  flex-direction: column;
}

.toolTipText {
  font-size: 12px;
  font-weight: 400;
  margin-left: 25px;
}

// .toolTip {
//   position: relative;
//   display: inline-block;
//   // border-bottom: 1px dotted black;
// }

// .toolTip .toolTipText {
//   visibility: hidden;
//   width: 200px;
//   background-color: $primaryColor;
//   color: #fff;
//   text-align: center;
//   border-radius: 6px;
//   padding: 5px 0;

//   /* Position the toolTip */
//   position: absolute;
//   z-index: 1;
// }

// .toolTip:hover .toolTipText {
//   visibility: visible;
// }

.coursePlanDiv {
  border: 1px solid #ccc;
  height: 75vh;
  padding: 10px;
  margin-top: 10px;
}

.courseTitle {
  // opacity: 0.7;
  background: transparent;
  color: #ffffff;
  // color: $primaryColor;
  font-weight: 700;
  font-size: 0.8rem;
  border-radius: none;
  box-shadow: none;
  // border-bottom: 0.0625rem solid #f0f2f5;
}

.labelTitle {
  // opacity: 0.7;
  background: transparent;
  color: $primaryColor;
  font-weight: 700;
  font-size: 0.8rem;
  border-radius: none;
  box-shadow: none;
  // border-bottom: 0.0625rem solid #f0f2f5;
}

.examQuestionTitle {
  color: black;
}

.courseSyllabusTitle {
  color: #7b809a;
  font-weight: 700;
  font-size: 1rem;
  border-radius: none;
  box-shadow: none;
}

.courseSyllabusSubtitle {
  color: #7b809a;
  font-weight: 300;
  font-size: 0.9rem;
  border-radius: none;
  box-shadow: none;
}

.dashboardNewWidget {

  // position: absolute !important;
  // bottom: 15px !important;
  .dashboardLink {
    color: white;

    &:hover {
      color: $primaryColor;
    }
  }
}

.newWidgetHolder {
  position: -webkit-sticky;
  position: sticky;
  bottom: 15px;
  z-index: 4;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  .searchWidget {
    background: linear-gradient(90deg,
        rgba(39, 115, 159, 0.729) 0%,
        rgba(39, 115, 159, 0.9) 0.01%,
        rgba(39, 115, 159, 0.702) 0.02%,
        rgba(39, 115, 159, 0.891) 100%);
    color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 10px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    padding: 10px;
    cursor: pointer;
    transition: 0.2s;

    .newWidgetLink {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .tt-widget-holder-new {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      &:hover {
        background-color: rgba(255, 255, 255, 0.7);
        color: $primaryColor;
      }
    }
  }

  .tt-widget-fontAwesome-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;
  }

  .footer-01 {
    background: linear-gradient(90deg,
        rgba(39, 115, 159, 0.729) 0%,
        rgba(39, 115, 159, 0.9) 0.01%,
        rgba(39, 115, 159, 0.702) 0.02%,
        rgba(39, 115, 159, 0.891) 100%);
    // height: 125px;
    color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 10px;
    width: 75%;
    flex: 1;

    .slick-track {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .slick-slide {
      border-right: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 108px;
      cursor: pointer;

      div {
        width: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.7);
        color: $primaryColor;
      }
    }

    .tt-widget-holder-new {
      max-width: 160px;
      // min-width: 160px;
      // border: 1px solid white;
      text-align: center;
      padding: 10px;
    }

    .slick-next:before,
    .slick-prev:before {
      font-size: 30px;
      color: white;
    }
  }
}

.footer-line {
  border-left: 1px solid white;
}

.errorMsg-textField {
  // position: absolute;
  color: red;
  font-size: 0.8rem;
}

.cusSelect__control {
  border-radius: 80px !important;
  font-size: 0.875rem;
  text-align: left;

  .cusSelect__placeholder {
    text-align: left;
    color: $cardTextColor !important;
  }

  .cusSelect__option {
    text-align: left;
  }
}

.examBox {
  border: 1px solid #ccc;
  padding: 10px;
}

.answerText {
  color: $cardTextColor;
  font-size: 0.875rem;
  font-weight: 500;
}

.close {
  font-size: 1.63rem;
}

// .modal-header .close {
//   background-color: rgb(228, 4, 4);
//   border: none;
//   color: white;
//   font-weight: 900;
//   border-radius: 5px;
//   font-size: 1.2rem;

//   &:hover {
//     background-color: rgb(255, 58, 58);
//   }
// }

// home page collage training
.sc-gswNZR dduubk {
  border: none !important;
}

//resource image training

.training-resources-img {
  position: relative;
  margin: 10px;
  height: 150px;
  width: 150px;

  button {
    position: absolute;
    background-color: red;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    color: white !important;
    right: 0;
  }

  img {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }
}

.langFlag {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  object-fit: contain;
}

.footer-section {
  .site-footer {
    background-color: #26272b;
    padding: 45px 0 20px;
    font-size: 15px;
    line-height: 24px;
    color: #737373;
  }

  .site-footer hr {
    border-top-color: #bbb;
    opacity: 0.5;
  }

  .site-footer hr.small {
    margin: 20px 0;
  }

  .site-footer h6 {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 5px;
    letter-spacing: 2px;
  }

  .site-footer a {
    color: #737373;
  }

  .site-footer a:hover {
    color: #3366cc;
    text-decoration: none;
  }

  .footer-links {
    padding-left: 0;
    list-style: none;
  }

  .footer-links li {
    display: block;
  }

  .footer-links a {
    color: #737373;
  }

  .footer-links a:active,
  .footer-links a:focus,
  .footer-links a:hover {
    color: #3366cc;
    text-decoration: none;
  }

  .footer-links.inline li {
    display: inline-block;
  }

  .site-footer .social-icons {
    text-align: right;
  }

  .site-footer .social-icons a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-left: 6px;
    margin-right: 0;
    border-radius: 100%;
    background-color: #33353d;
  }

  .copyright-text {
    margin: 0;
  }

  @media (max-width: 991px) {
    .site-footer [class^="col-"] {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 767px) {
    .site-footer {
      padding-bottom: 0;
    }

    .site-footer .copyright-text,
    .site-footer .social-icons {
      text-align: center;
    }
  }

  .social-icons {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .social-icons li {
    display: inline-block;
    margin-bottom: 4px;

    img {
      height: 60px;
    }
  }

  .social-icons li.title {
    margin-right: 15px;
    text-transform: uppercase;
    color: #96a2b2;
    font-weight: 700;
    font-size: 13px;
  }

  .social-icons a {
    background-color: #eceeef;
    color: #818a91;
    font-size: 16px;
    display: inline-block;
    line-height: 44px;
    width: 44px;
    height: 44px;
    text-align: center;
    margin-right: 8px;
    border-radius: 100%;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }

  .social-icons a:active,
  .social-icons a:focus,
  .social-icons a:hover {
    color: #fff;
    background-color: #29aafe;
  }

  .social-icons.size-sm a {
    line-height: 34px;
    height: 34px;
    width: 34px;
    font-size: 14px;
  }

  .social-icons a.facebook:hover {
    background-color: #3b5998;
  }

  .social-icons a.twitter:hover {
    background-color: #00aced;
  }

  .social-icons a.linkedin:hover {
    background-color: #007bb6;
  }

  .social-icons a.dribbble:hover {
    background-color: #ea4c89;
  }

  @media (max-width: 767px) {
    .social-icons li.title {
      display: block;
      margin-right: 0;
      font-weight: 600;
    }
  }
}

.float-feedback-icon {
  position: fixed;
  width: 50px;
  height: 150px;
  bottom: 40px;
  left: 0;
  background-color: #4878ae;
  color: #fff;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  padding-top: 15px;

  &:hover {
    cursor: pointer;
  }

  p {
    transform: rotate(-90deg);
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.feedback-fa-icon {
  height: 20px;
  margin-left: 0.6rem;
}

.mobileLogoDiv {
  display: flex;

  .mobileGovHeader {

    // display: block !important;
    .govNepalTitle {
      color: #b6161b;
      font-weight: bold;
      font-size: 13px;
      margin-bottom: 5px;
    }

    .govNepalMinistryTitle {
      color: #b6161b;
      font-weight: 500;
      font-size: 13px;
      margin-bottom: 5px;
    }

    .govNepalSubTitle {
      color: #b6161b;
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 5px;
    }

    .learningPortalText {
      color: #2358b2;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 0;
    }
  }
}

.learningPortalText {
  color: #2358b2;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0;
}

.langMenuDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  a {
    padding: 1px 8px;
    font-weight: 400;
  }
}

.mobileLangMenu {
  display: none !important;
}

// Responsive
@media screen and (min-width: 1800px) {
  .bigContainer {
    max-width: 80% !important;
  }
}

@media screen and (min-width: 1500px) {
  .bigContainer {
    max-width: 90%;
  }
}

@media screen and (max-width: 1550px) {
  .mobileLogoDiv {
    width: 35%;
  }
}

@media screen and (max-width: 1366px) {
  .login-toggler {
    padding: 15px !important;
  }
}

@media screen and (max-width: 991px) {
  .cusBtnFontSize {
    font-size: 0.665rem;
    margin: 0px 5px;
  }

  .langMenuDiv {
    display: none;
  }

  .mobileLangMenu {
    display: block !important;

    ul {
      flex-direction: row;
      width: 100%;
      padding-left: 10px;

      li {
        padding-right: 10px;
      }
    }
  }

  .mobileLogoDiv {
    width: 90%;
  }

  .mobileGovPhotoHeader {
    display: none !important;
  }

  .loggedInNavbar {
    justify-content: flex-start !important;
  }

  .mobileGovHeader {
    display: block !important;

    .govNepalTitle {
      color: #b6161b;
      font-weight: bold;
      font-size: 13px;
      margin-bottom: 5px;
    }

    .govNepalMinistryTitle {
      color: #b6161b;
      font-weight: 500;
      font-size: 13px;
      margin-bottom: 5px;
    }

    .govNepalSubTitle {
      color: #b6161b;
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 5px;
    }

    .learningPortalText {
      color: #2358b2;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .loginDiv {
    height: auto;

    .loginBg {
      display: none;
    }
  }

  .subjectMobileMenu {
    display: none;
  }

  .carouselCaption {
    h1 {
      font-size: 1.3rem !important;
    }
  }

  .resourceBtn {
    font-size: 12px;
  }

  .mobileContainer {
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media screen and (max-width: 700px) {
  .loginDiv {
    .loginBoxDiv {
      .loginBoxHolder {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .navLogo {
    width: 75px;
  }

  .mobileGovHeader {

    .govNepalTitle,
    .govNepalMinistryTitle {
      font-size: 12px;
    }

    .govNepalSubTitle {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 450px) {
  .cusBtnFontSize {
    font-size: 0.495rem;
    margin: 0px 3px;
  }

  .mobileLogoDiv {
    width: 85%;
  }
}

@media screen and (max-width: 420px) {
  .carouselCaption {
    h1 {
      font-size: 1.1rem !important;
    }

    p {
      font-size: 0.75rem;
    }
  }

  .listBox {
    .listTitle {
      font-size: 1.1rem !important;
    }
  }

  .navLogo {
    width: 70px;
  }

  .mobileGovHeader {

    .govNepalTitle,
    .govNepalMinistryTitle {
      font-size: 11px;
    }

    .govNepalSubTitle {
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 360px) {
  .carouselCaption {
    width: 70% !important;

    h1 {
      font-size: 1rem !important;
    }
  }
}

@media screen and (max-width: 330px) {
  .mobileGovHeader {
    .learningPortalText {
      font-size: 17px;
    }
  }
}

.website-config-container img {
  width: auto;
  height: 100px;
}

// notices section front page

.noticeBox {
  height: 100px;
  width: 100%;
  display: flex;
  // justify-content: space-around;
  border-radius: 10px;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.088);
  overflow: hidden;
  padding: 0;
  margin-bottom: 25px;

  &:hover {
    cursor: pointer;
  }

  .notice-date {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007bb6;
    color: white;
    font-weight: 600;
    padding: 10px;
  }

  .notice-data {
    padding: 10px;
    width: 100%;
    background-color: rgb(241, 249, 252);
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: 600;
      font-size: 1rem;
      // line-height: 0px;
      margin: 0;
      color: #4878ae;
    }
  }
}

.alternateBg {
  background-color: rgb(241, 249, 252);
}

.gallery-img {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.136), 0 3px 8px 0 rgba(0, 0, 0, 0.088);
  margin: 10px;
  height: 150px;

  .img-box {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      background: white;
      padding: 10px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        object-fit: cover;
        transition: 0.3s;
      }
    }
  }
}

video {
  width: 100%;
}

.drag-drop-upload {
  #form-file-upload {
    height: 10rem;
    // width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  #input-file-upload {
    display: none;
  }

  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
  }

  #label-file-upload.drag-active {
    background-color: #ffffff;
  }

  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: "Oswald", sans-serif;
    background-color: transparent;
  }

  .upload-button:hover {
    text-decoration-line: underline;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}

.govHeader {
  background: white;
  display: flex;
  justify-content: center;
  // height: 120px;
  height: 25vh;
  width: 100%;

  .govHeader-img {
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.footerBg {
  // background-color: $primaryBtnColor;
  background-color: $whiteColor;
  height: 100px;

  // box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
  //   0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  // margin-top: 5px;
  // display: flex;
  // justify-content: center;
  .footerBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    // padding-top: 10px;
    // padding-bottom: 10px;
    p {
      margin-bottom: 0;
      color: white;
      font-weight: 500;
    }

    .footerLogoBox {
      height: 80px;
      margin-left: 20px;

      // width: 40px;
      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
}

.sidebarFooter {
  background-color: white;
  padding-bottom: 5px;
  padding-top: 12px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  img {
    height: 70px;
    width: auto;
    object-fit: contain;
  }
}

.footerBorder {
  border-top: 1px solid #4878ae;
}

.footerFlexDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: inherit;
}

.footerFlexDivStart {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: inherit;
}

.footerTextBottom {
  color: #146596 !important;
  font-size: 15px;
  margin-left: 10px;
}

.sliderBox {
  position: fixed;
  width: 250px;
  height: 90px;
  // bottom: 40px;
  bottom: 180px;
  right: -165px;
  background-color: white;
  color: #fff;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  // box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.33);
  overflow: hidden;
  transition: 0.3s;
  cursor: pointer;
  padding: 10px;

  .insideSliderBox1 {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sliderPoweredText {
      color: black;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .sliderPoweredImage {
      width: 50px;
      height: 50px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .insideBreaker {
    width: 5%;
  }

  .insideSliderBox2 {
    width: 65%;
    text-align: left;
    height: 100%;
    border-left: 1px solid #ccc;
    padding: 5px;
    padding-left: 15px;
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
      color: black;
      font-weight: 600;
      font-size: 11px;

      span {
        font-weight: 600;
        color: $logoColor;
        // text-shadow: 0 1px 1px rgba(0, 0, 0, 0.33);
      }
    }
  }

  // &:hover {
  //   right: 0;
  //   opacity: 1;
  // }
}

//dashboard page responsive

.dashboard-page-central {
  .whiteContainer {
    margin-bottom: 30px;
  }
}

.loggedInNavbar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@media only screen and (max-width: 1000px) and (min-width: 400px) {


  .dashboard-page-central {
    .dashboard-card-50 {
      width: 33.33%;

      .whiteContainer {
        .cusCardHeader {
          flex-direction: column;

          .cusCardLabelBox {
            margin-top: 10px;

            .cusCardLabel {
              font-size: 10px;
            }

            .cusCardValue {
              font-size: 15px;
            }
          }

          .cusCardIcon {
            .cardIconBox {
              width: 2rem;
              height: 2rem;
              margin-top: -25px;

              .material-icons-round {
                font-size: 12px;
              }
            }
          }
        }

        .cardSeparator {
          margin: 0.5rem;
        }

        .cusCardContent {
          font-size: 10px;
        }
      }
    }
  }
}

.material-icons-round:hover {
  cursor: pointer;
}

// Government Header
.govHeaderDiv {
  background-color: #003594;
  position: relative;
  height: 320px;
  color: white;
  padding: 35px;

  .govSubtitle {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 300;
  }

  .govMainTitle {
    font-size: 18px;
    font-weight: 600;
  }

  .learnPortal {
    font-size: 20px;
    margin-top: 2rem;
    font-weight: 600;
  }

  .govProfileDiv {
    position: absolute;
    bottom: -50px;
    left: 10%;
    z-index: 100;
    display: flex;
    align-items: center;

    .govProfile {
      width: 130px;
      height: 130px;
      overflow: hidden;
      border-radius: 50%;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.33);
      border: 3px solid #003594;
      background: white;
      padding: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .govProfileName {
      font-size: 13px;
      margin-left: 5px;
    }
  }
}

.gradePageHead {
  text-align: center;
  width: 65%;
  margin-left: auto;
  margin-right: auto;

  h2 {
    font-size: 1.5rem;
  }
}

.govHeaderCoverPicDiv {
  height: 320px;

  .govSliderImg {
    height: 320px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0;
    }
  }
}

// .mobileGovHeader {
//   display: none;
// }

// Responsive Design

@media screen and (max-width: 767px) {
  .mobileSidebar {
    padding: 10px;
    display: block;
    position: absolute;
    bottom: 0;
    z-index: 1000;
    background: linear-gradient(195deg, $sidebarHover1, $sidebarHover2);
    width: 95%;
    text-align: center;
    color: white;
    left: 2.5%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .loggedInNavbar {
    justify-content: flex-start;
  }

  .sidebarCss {
    display: none;
    position: absolute !important;
    z-index: 9;
    width: 90% !important;
  }

  audio {
    width: 100%;
  }

  .cusTable {
    overflow-x: auto;
    display: block;
    width: 100%;
  }

  .footerBg {
    height: auto;

    .footerBox {
      .footerLogoBox {
        height: 50px;
      }
    }

    .footerFlexDivStart {
      justify-content: center;
      margin-top: 10px;
    }

    .footerFlexDiv {
      justify-content: center;
    }
  }
}

.activeTeacherSelect {
  background-color: $primaryBtnColor !important;
  color: white !important;
}

// * pagination-container */

.pagination-div {
  margin: 20px auto;
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
}

.pagination-div>.active>a {
  background-color: $primaryBtnColor;
  border-color: $primaryBtnHoverColor;
  color: #fff;
}

.pagination-div>li>a {
  border: 1px solid $primaryBtnHoverColor;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination-div>.active>a,
.pagination-div>.active>span,
.pagination-div>.active>a:hover,
.pagination-div>.active>span:hover,
.pagination-div>.active>a:focus,
.pagination-div>.active>span:focus {
  background-color: $primaryBtnHoverColor;
  border-color: $primaryBtnHoverColor;
  outline: none;
}

.pagination-div>li>a,
.pagination-div>li>span {
  color: $primaryBtnHoverColor;
}

.pagination-div>li:first-child>a,
.pagination-div>li:first-child>span,
.pagination-div>li:last-child>a,
.pagination-div>li:last-child>span {
  border-radius: unset;
}

#react-doc-viewer {
  height: 100%;
  #header-bar {
    display: none;
  }
}

.file-list-col {
  ul {
    li {
      margin-bottom: 10px;
    }
  }
}

.resourceDeleteIcon {
  font-size: 18px;
  position: absolute;
  right: 20px;
  // top: 8px;
  color: white;
  cursor: pointer;
  text-align: right;
}

.fileModalDivide {
  .fileViewerHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
  }

  .fileViewerHead-md {
    display: flex;
    align-items: center;

    padding: 0 20px;
  }

  .resourceDownloadDiv {
    position: absolute;
    z-index: 1000;
    bottom: 55px;
    right: 60px;

    button {
      border-radius: 50%;
      background-color: #007bb6;
      color: white;
      border: none;
      padding: 10px;

      &:hover {
        box-shadow: 2px 2px 5px 1px rgba(52, 52, 52, 0.523);
      }
    }
  }

  .ham-button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    transition: all 0.25s ease;

    &:hover {
      opacity: 0.8;
      transition: all 0.25s ease;
    }
  }

  .fileListMenu {
    width: 400px;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    transition: all 0.25s ease;
    transform: translateX(-50%);
    top: 0;
    left: 0;
    overflow: scroll;
    overflow-x: hidden;

    background-color: $primaryBtnColor;

    height: 100%;
    z-index: 100;
    padding-top: 20px;

    h2 {
      font-size: medium;
      color: white;
      margin: 0 20px;
    }

    ul li {
      margin: 10px;
      color: white;

      a {
        text-decoration: none;
        color: white;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .activeFileList {
    opacity: 1;
    visibility: visible;
    transition: all 0.25s ease;
    transform: translateX(0);
  }
}

// @-webkit-keyframes fadeInRight {
//   0% {
//     opacity: 0;
//     left: 20%;
//   }
//   100% {
//     opacity: 1;
//     left: 0;
//   }
// }

.pg-driver-view {
  .video-container {
    video {
      max-height: 70vh;
    }
  }
}

.css-2613qy-menu {
  height: auto;
  z-index: 1000 !important;
  color: red;
}

// .content-widget{
//   display: flex;
//   justify-content: center;
//   height: 100%;
//   flex-direction: column;
// }

.mainContainerDashboard {
  height: calc(100vh - 144px);
  overflow: auto;
  position: relative;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .centralWidgetHolder {
    display: none !important;
  }

  .mainContainerDashboard {
    height: calc(100vh - 50px);
  }
}

.centralWidgetHolder {
  // position: -webkit-sticky;
  // position: rel;
  // bottom: 15px;
  // z-index: 4;
  // width: 80%;
  margin-left: auto;
  margin-right: 10px;
  display: flex;

  .tt-widget-fontAwesome-icon {
    width: 35px;
    height: 35px;
    margin-bottom: 8px;
  }

  .footer-01 {
    // background: linear-gradient(
    //   90deg,
    //   rgba(0, 0, 0, 0.729) 0%,
    //   rgba(0, 0, 0, 0.9) 0.01%,
    //   rgba(0, 0, 0, 0.702) 0.02%,
    //   rgba(0, 0, 0, 0.891) 100%
    // );
    background: linear-gradient(195deg, #00236690, #002366) !important;
    // height: 125px;
    color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.735rem;
    padding: 10px;
    width: 75%;
    flex: 1;

    .slick-track {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .slick-slide {
      border-right: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 108px;
      cursor: pointer;

      div {
        width: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        background-color: rgb(255, 255, 255);
        color: $primaryColor;

        .tt-widget-fontAwesome-icon {
          color: $primaryColor;
        }

        .tt-widget-title {
          color: $primaryColor;
        }
      }
    }

    .tt-widget-holder-new {
      max-width: 160px;
      // min-width: 160px;
      // border: 1px solid white;
      text-align: center;
      padding: 10px;
    }

    .slick-next:before,
    .slick-prev:before {
      font-size: 30px;
      color: white;
    }
  }
}