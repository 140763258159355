@import "./variables";

.tt-mcqQuestionBox {
  border: 1px solid $calendarBorderColor;
}

.mcqAddButtons {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    width: 75%;
  }
}

.removeMcqOption {
  font-size: 16px;
  position: relative;
  top: 8px;
  color: $dangerBtnColor;
  cursor: pointer;
}

.addMcqOption {
  font-size: 16px;
  position: relative;
  top: 8px;
  color: $primaryBtnColor;
  cursor: pointer;
}

.tt-addExplanation {
  padding: 6px 6px 6px 15px !important;
  margin-bottom: 5px;
  background-color: $primaryColor !important;
  font-weight: bold;
  letter-spacing: 0.5px;
  cursor: default;
  color: white;
  -webkit-user-select: none;
  user-select: none;
  margin-left: 10px;
  margin-right: 10px;
}

.tt-explanationCard {
  margin: -5px 9px 5px 9px;
  .jodit_container {
    min-height: 135px !important;
  }
  .jodit_statusbar {
    display: none;
  }
  .jodit .jodit_workplace,
  .jodit_container .jodit_workplace {
    min-height: 100px !important;
    .jodit_wysiwyg {
      min-height: 100px !important;
    }
  }
}

.timer {
  font-size: 18px;
  font-weight: 500;
  color: $whiteColor;
  background-color: $primaryHoverColor;
  display: inline-flex;
  padding: 0 15px;
  border-radius: 25px;
  margin-right: 10px;
}

.tt-mcqTestHead {
  color: $primaryHoverColor;
  h4 {
    margin-bottom: 0;
  }
}

.question1 {
  font-size: 18px;
  font-weight: 600;
}

.answerOptions {
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
  margin-bottom: 8px;
}

.answerOption {
  border-bottom: 1px solid #eee;
  padding: 5px 0 5px 0;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: $primaryHoverColor;
    color: $whiteColor;
  }
  input[type="radio"] {
    height: 20px;
    width: 20px;
    left: 18px;
    position: relative;
    top: 1px;
    cursor: pointer;
  }
}

.tt-mcqResultOption {
  padding-top: 8px;
  cursor: default;
  &:hover {
    background-color: unset;
    color: black;
  }
  .fa-circle {
    font-size: 20px;
    margin-left: 16px;
    border: 1px solid #ccc;
    background: $whiteColor !important;
    border-radius: 25px;
    color: white;
  }
  .correctOption {
    color: $primaryColor;
    border: none;
  }
  .tt-correct {
    color: green;
  }
  .tt-wrong {
    color: $dangerBtnColor;
  }
  .answerChoice {
    padding-left: 10px;
  }
}

.correctAnswer {
  box-shadow: inset 2px 3px 70px $mcqCorrect;
}

.wrongAnswer {
  box-shadow: inset 2px 3px 70px $mcqWrong;
}

//
.answerChoice {
  text-align: left !important;
  padding-left: 25px;
  position: relative;
  top: -3px;
}

.tt-mcqQuestionHolder {
  border: 1px solid #ccc;
  padding: 8px 8px 0 8px;
  margin-bottom: 8px;
  margin-top: 5px;
}

.tt-timerSticky {
  position: sticky;
  top: 0;
  z-index: 999;
}

.mcqTableHeader {
  tr {
    th {
      padding: 8px !important;
    }
  }
}

.mcqTableSubHeader {
  tr {
    th {
      padding: 5px !important;
    }
  }
}
