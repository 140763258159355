@import "./variables";

.performanceChartDiv {
  background-color: #f4f4f4;
  box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
    0rem 0.4375rem 0.625rem -0.3125rem rgba(0, 0, 0, 0.4);
  padding: 3rem;
  margin-top: 2.5rem;
  .performanceChartHolder {
    background-color: white;
    padding: 25px;
  }
  .attLabel {
    font-size: 17px;
    font-weight: bold;
    color: $primaryColor;
    text-align: center;
    margin-top: 10px;
  }
}

.overallAch {
  font-size: 20px;
  font-weight: 600;
  color: $primaryColor;
}

.studentInfoDiv {
  background-color: #f4f4f4;
  box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
    0rem 0.4375rem 0.625rem -0.3125rem rgba(0, 0, 0, 0.4);
  padding: 25px;
  .performanceTable {
    border: none !important;
    th,
    td {
      vertical-align: middle !important;
      border-top: unset !important;
    }
  }
  .studentPhotoHolder {
    width: 120px;
    height: 120px;
    background-color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .stuFullName {
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.stuContactDetailDiv {
  border: 1px solid #ccc;
  //   border-bottom: 0;
  table {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
    color: $primaryColor;
    font-weight: bold;
    padding: 10px;
    font-size: 16px;
  }
}

.stuTerminalMarksDiv {
  margin-top: 2.5rem;
  border: 1px solid #ccc;
  padding: 10px;
  p {
    margin-bottom: 0;
    color: $primaryColor;
    font-weight: bold;
    padding: 10px;
    font-size: 16px;
  }
}
